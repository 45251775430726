import { v3 } from "backoffice-api"
import { useModal, useQueryParam, useRouteParamNumber } from "hooks"
import { Loader } from "materia"
import { QueryBoundary } from "utility-components"
import { createPlaythrough } from "../../bonzai/createPlaythrough"
import { getVideoLink } from "../../dataUtilities/getVideoLink"
import { getPreferredContentLink } from "../../dataUtilities/productDataUtilities"
import { usePickText } from "../../i18n/usePickText"
import { ViewerModal } from "./ViewerModal"

type VideoModalLoaderProps = {
  closeRoute: string
}
export const VideoModalLoader = ({ closeRoute }: VideoModalLoaderProps) => {
  const productId = useRouteParamNumber("productId")

  const { onClose } = useModal(closeRoute, () => {
    createPlaythrough(productId, {
      mutate: false,
      optimisticOneStar: true,
      invalidateDependencies: true,
    })
  })

  return (
    <ViewerModal>
      <QueryBoundary fallback={<Loader />}>
        <Load productId={productId} onClose={onClose} />
      </QueryBoundary>
    </ViewerModal>
  )
}

type VideoViewerLoaderProps = {
  productId: number
  onClose: () => void
}
const Load = ({ productId, onClose }: VideoViewerLoaderProps) => {
  const pickText = usePickText()

  const product = v3.getProduct.useQuery(
    [productId, { include: ["locale_content_links"] }],
    {
      select: (res) => res.data,
    }
  )
  const [content_locale] = useQueryParam("content_locale")
  const contentLink = getPreferredContentLink(product, content_locale)
  const videoLink = getVideoLink(contentLink)

  return (
    <ViewerModal.FullScreen
      headerText={pickText(product.title)}
      src={videoLink}
      onClose={onClose}
    />
  )
}
