import { Outlet, ReactLocation, Router } from "@tanstack/react-location"
import { auth } from "auth"
import { BonzaiProvider } from "bonzai"
import { PortalTarget } from "materia"
import { APP_CONFIG } from "optimizer-core"
import { useState } from "react"
import { OptimizeContextProvider } from "ui-tools"
import { QueryBoundary } from "utility-components"
import { AppShellLoader } from "./components/AppShell/AppShellLoader"
import { CompletedProductBannerLoader } from "./components/CompletedProductBanner/CompletedProductBannerLoader"
import { OPTIMIZER_URL } from "./env"
import { getRoutes } from "./routes"

const APP = APP_CONFIG.find((app) => app.name === "portal")

export const App = () => (
  <BonzaiProvider>
    <OptimizeContextProvider value={{ app: APP, optimizeUrl: OPTIMIZER_URL }}>
      <PortalTarget>
        <QueryBoundary>
          <AppRouter />
          <CompletedProductBannerLoader />
        </QueryBoundary>
      </PortalTarget>
    </OptimizeContextProvider>
  </BonzaiProvider>
)

const AppRouter = () => {
  const session = auth.getActiveSession.useQuery()
  const [location] = useState(() => new ReactLocation())

  return (
    <Router
      routes={getRoutes()}
      location={location}
      basepath={session.subdomain}
    >
      <AppShellLoader>
        <Outlet />
      </AppShellLoader>
    </Router>
  )
}
