import activityLeaderboardIcon from "assets/icons/activityLeaderboardIcon.svg"
import completedIcon from "assets/icons/completedIcon.svg"
import starFilled from "assets/icons/starFilled.svg"
import type { ReactNode } from "react"
import { modifiers } from "ui-tools"
import {
  FiestaImage,
  FiestaLink,
  type FiestaLinkProps,
} from "utility-components"
import s from "./RecentActivityWidgetMobile.module.scss"

export const RecentActivityWidgetMobile = ({
  children,
}: {
  children: ReactNode
}) => {
  return (
    <div className={s.recentActivityWidget}>
      <div className={s.widget}>{children}</div>
    </div>
  )
}

type EntryType = "stars" | "completed" | "leaderboard"

const Entry = ({
  link,
  image,
  name,
  timestamp,
  summaryText,
  productTitle,
  type,
  arrow,
  isActive,
}: {
  link: FiestaLinkProps
  image: string
  name: string
  productTitle: string
  timestamp: string
  summaryText: string
  type: EntryType
  arrow: string
  isActive: boolean
}) => {
  const entryClass = modifiers(s, "entry", {
    orange: type === "leaderboard",
    green: type === "completed",
    isActive,
  })

  const getIcon = () => {
    if (type === "completed") return completedIcon
    if (type === "leaderboard") return activityLeaderboardIcon
    if (type === "stars") return starFilled
  }

  return (
    <FiestaLink {...link} className={entryClass}>
      <img className={s.entry__avatar} src={image} alt="" />
      <div className={s.entry__userSummary}>
        <div className={s.entry__summaryTop}>
          <div className={s.entry__name}>{name}</div>
          <div className={s.entry__time}>{timestamp}</div>
        </div>
        <div className={s.entry__summaryBottom}>
          <img className={s.entry__icon} src={getIcon()} alt="" />
          <div className={s.entry__summaryText}>{summaryText}</div>
          <div className={s.entry__productTitle}>{productTitle}</div>
        </div>
      </div>

      <div className={s.entry__arrowContainer}>
        <FiestaImage
          className={s.entry__arrow}
          src={arrow}
          alt=""
          sizes="20px"
        />
      </div>
    </FiestaLink>
  )
}

type EmptyProps = {
  emptyText: string
}
const Empty = ({ emptyText }: EmptyProps) => (
  <div className={s.empty}>{emptyText}</div>
)

const Skeleton = () => {
  return (
    <div className={s.skeleton}>
      <div className={s.skeleton__avatar} />
      <div className={s.skeleton__userSummary}>
        <div className={s.skeleton__title} />
        <div className={s.skeleton__summary} />
      </div>
      <div className={s.skeleton__arrow} />
    </div>
  )
}

RecentActivityWidgetMobile.Entry = Entry
RecentActivityWidgetMobile.Skeleton = Skeleton
RecentActivityWidgetMobile.Empty = Empty
