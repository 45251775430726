import { Link, useSearch, type LinkProps } from "@tanstack/react-location"
import type { ReactNode } from "react"

export type FiestaLinkProps = {
  href?: string
  onClick?: () => void
  resetSearchParams?: boolean
  children?: ReactNode
  className?: string
  to?: LinkProps["to"]
  search?: LinkProps["search"]
  getActiveProps?: LinkProps["getActiveProps"]
  title?: string
  target?: "_blank"
}

export const FiestaLink = (props: FiestaLinkProps): JSX.Element => {
  if (props.href) return <ExternalLink {...props} />
  if (props.search || props.to) return <InternalLink {...props} />
  if (props.onClick) return <Button {...props} />
  return <ExternalLink {...props} />
}

const ExternalLink = ({
  children,
  className,
  href,
  title,
  onClick,
  target,
}: FiestaLinkProps) => (
  <a
    className={className}
    href={href}
    title={title}
    onClick={onClick}
    target={target}
    rel="noreferrer"
  >
    {children}
  </a>
)

const Button = ({ className, children, title, onClick }: FiestaLinkProps) => (
  <button className={className} title={title} onClick={onClick}>
    {children}
  </button>
)

const InternalLink = ({
  className,
  resetSearchParams,
  search,
  title,
  children,
  onClick,
  to,
  getActiveProps,
  target,
}: FiestaLinkProps) => {
  const searchParams = useSearch()
  if (!search && !resetSearchParams) search = searchParams

  return (
    <Link
      className={className}
      search={search}
      title={title}
      onClick={onClick}
      to={to}
      getActiveProps={getActiveProps}
      target={target}
    >
      {children}
    </Link>
  )
}
