import { v1, v3 } from "backoffice-api"
import { waitMillis } from "utils"
import { custom } from "./bonzai"

/**
 * This is complicated because the api is slow and inconsistent when processing playthroughs.
 * We wouldn't need any of this if the apis were improved.
 */

type Config = {
  mutate?: boolean // Send playthrough to api
  invalidate?: boolean // Trigger refetching of product status
  invalidateDependencies?: boolean // Trigger refetching of other endpoints dependent on playthroughs
  optimisticOneStar?: boolean // Give one star immediately
  optimisticOnFocus?: boolean // Wait with the star until window receives focus
}

export const createPlaythrough = async (
  productId: number | string,
  {
    mutate = true,
    invalidate = false,
    invalidateDependencies = false,
    optimisticOneStar = false,
    optimisticOnFocus = false,
  }: Config = {}
) => {
  /**
   * V1 stores IDs as numbers and V3 as strings.
   * We cannot move fully to V3 yet as it's not ready,
   * so we reconcille V1 and V3 types here
   * TODO: https://jira.attensi.com/browse/WEB-14081
   */
  const id = typeof productId === "string" ? Number(productId) : productId
  const isNoScoreProduct = await getIsNoScore(id)
  if (isNoScoreProduct) return

  if (optimisticOneStar) {
    if (optimisticOnFocus) {
      onFocusOnce(() => optimisticUpdate(id))
    } else {
      optimisticUpdate(id)
    }
  }

  if (mutate) {
    await apiUpdate(id)
  }

  if (invalidateDependencies) {
    invalidatePlaythroughDependencies()
  }

  if (invalidate) {
    invalidatePlaythrough(id)
  }
}

const getIsNoScore = async (productId: number) => {
  const { data: product } = await v3.getProduct.fetchQueryOnce([productId])
  return product.no_score
}

const optimisticUpdate = (productId: number) => {
  custom.getProductProgress.setQueriesData([productId], (status) => {
    if (status.stars > 0) return

    incrementUserStatus(status.is_mandatory)

    status.high_score = 100
    status.certified = true
    status.stars = 1
  })

  v3.getProductDeadline.setQueriesData([productId], (deadline) => {
    if (deadline.data === null) return

    deadline.data.status = "certified"
  })
}

const incrementUserStatus = (isMandatory: boolean) => {
  v1.getUserStatus.setQueriesData((data) => {
    data.stars.count += 1
    data.certified.count += 1

    if (isMandatory) {
      data.mandatory.count += 1
    }
  })
}

const apiUpdate = async (productId: number) => {
  await v1.createPlaythrough(productId)
}

export const invalidatePlaythrough = async (productId: number) => {
  custom.getProductProgress.invalidateQueries([productId])

  // In case the backend hasn't processed the playthrough yet
  await waitMillis(1000)

  custom.getProductProgress.invalidateQueries([productId])
  v3.getProductDeadline.invalidateQueries([productId])
}

export const invalidateAllPlaythroughs = () => {
  custom.getProductProgress.invalidateQueries()
  v3.getProductDeadline.invalidateQueries()
}

export const invalidatePlaythroughDependencies = () => {
  v3.getProductCategoryProgress.invalidateQueries() // Journey progress
  v3.getBundleProgress.invalidateQueries() // Bundle progress
}

const onFocusOnce = (handler: () => void) => {
  const onFocus = () => {
    window.removeEventListener("focus", onFocus)
    handler()
  }

  window.addEventListener("focus", onFocus)
}
