import { auth } from "auth"
import {
  API_TOKEN,
  APP_ENV,
  AUTH_URL,
  BACKOFFICE_API_URL,
  LOGIN_URL,
} from "../env"

export const initializeAuth = () => {
  auth.initializeAuth({
    authUrl: AUTH_URL,
    loginUrl: LOGIN_URL,
    backofficeUrl: BACKOFFICE_API_URL,
    apiToken: API_TOKEN,
    subdomain: auth.getSubdomainFromPath(),
    environment: APP_ENV,
    locale: undefined,
  })
}
