import { v1, v3 } from "backoffice-api"
import { useTranslation } from "react-i18next"
import { QueryBoundary } from "utility-components"
import { usePickText } from "../../i18n/usePickText"
import { HiddenHeader } from "../HiddenHeader"
import { JourneyTeaser } from "./JourneyTeaser"

export const JourneyTeaserLoader = () => (
  <QueryBoundary fallback={<JourneyTeaser.Skeleton />}>
    <Load />
  </QueryBoundary>
)

const Load = () => {
  const pickText = usePickText()
  const { t } = useTranslation()
  const { journey, progress } = useFirstJourneyProgress()

  if (!journey) return null

  return (
    <>
      <HiddenHeader title={t("journey.CONTINUE_IN_JOURNEY")} tag="h2" />
      <JourneyTeaser
        link={{ to: "/journey" }}
        backgroundImage={journey.background_image_url ?? ""}
        title={pickText(journey.titles)} // TODO: use pickText with v3
        certified={progress?.certified_count ?? 0}
        certifiedMax={progress?.certified_max ?? 0}
        stars={progress?.stars_count ?? 0}
        starsMax={progress?.stars_max ?? 0}
      />
    </>
  )
}

const useFirstJourneyProgress = () => {
  const { i18n } = useTranslation()

  const journey = v1.getProductCategories.useQuery(
    [{ journey: true, per_page: 1, locale: i18n.language }],
    { select: (res) => res.data.find((category) => !category.palp_only) }
  )

  const progress = v3.getProductCategoryProgress.useQuery([journey?.id!], {
    enabled: journey !== undefined,
    suspense: false,
    select: (res) => res.data,
  })

  return { journey, progress }
}
