import { useWindowWidth } from "materia"
import type { ReactNode } from "react"
import { getChromaticProps } from "ui-tools"
import { FeaturedProducts } from "../FeaturedProducts/FeaturedProducts"
import { JourneyTeaser } from "../JourneyTeaser/JourneyTeaser"
import { NewsWidgetMobile } from "../NewsWidgetMobile/NewsWidgetMobile"
import { ProductList } from "../ProductList/ProductList"
import { RecentActivityWidgetMobile } from "../RecentActivityWidgetMobile/RecentActivityWidgetMobile"
import s from "./ForYouView.module.scss"

type ForYouViewProps = {
  children: ReactNode
  chromaticIgnore?: boolean
}
export const ForYouView = ({ children, chromaticIgnore }: ForYouViewProps) => (
  <div className={s.view} {...getChromaticProps(chromaticIgnore)}>
    {children}
  </div>
)

type CategoryProps = {
  title: string
  children: ReactNode
}
const Category = ({ title, children }: CategoryProps) => (
  <div className={s.category}>
    <h2 className={s.category__title}>{title}</h2>
    {children}
  </div>
)

const Skeleton = () => {
  const { isBigWindow } = useWindowWidth()
  return (
    <ForYouView>
      <FeaturedProducts.Skeleton />
      <JourneyTeaser.Skeleton />
      {!isBigWindow && <NewsWidgetMobile.Skeleton />}
      {!isBigWindow && <RecentActivityWidgetMobile.Skeleton />}
      <CategorySkeleton />
      <CategorySkeleton />
      <CategorySkeleton />
    </ForYouView>
  )
}

const CategorySkeleton = () => (
  <div className={s.skeleton}>
    <div className={s.skeleton__title} />
    <ProductList.Skeleton />
  </div>
)

ForYouView.Category = Category
ForYouView.Skeleton = Skeleton
ForYouView.CategorySkeleton = CategorySkeleton
