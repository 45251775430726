import { v3 } from "backoffice-api"
import { useQueryAll } from "bonzai"
import { QueryBoundary } from "utility-components"
import { useSnapshot } from "valtio"
import { custom } from "../../bonzai/bonzai"
import { getStartAppLink } from "../../customApi"
import { getMaxStarsForProduct } from "../../dataUtilities/productDataUtilities"
import { usePickText } from "../../i18n/usePickText"
import { sendOpenProductMessage } from "../../vuplex/sendOpenProductMessage"
import { vuplexStore } from "../../vuplex/vuplexStore"
import { ProductPlaying } from "./ProductPlaying"

export const ProductPlayingLoader = () => {
  const { productId } = useSnapshot(vuplexStore)

  if (productId === undefined) return null

  return (
    <QueryBoundary>
      <Load productId={productId} />
    </QueryBoundary>
  )
}

type ProductProps = {
  productId: number
}
const Load = ({ productId }: ProductProps) => {
  const [product, status] = useData(productId)
  const { product_type, no_score, product_image_url, title } = product

  const pickText = usePickText()

  const openProduct = async () => {
    const link = await getStartAppLink(product)
    if (link) sendOpenProductMessage(product, link)
  }

  return (
    <ProductPlaying
      onClick={openProduct}
      image={product_image_url}
      progress={status.percentage}
      progressMax={100}
      stars={status.stars}
      starsMax={getMaxStarsForProduct(product_type.identifier, !no_score)}
      title={pickText(title)}
    />
  )
}

const useData = (productId: number) => {
  return useQueryAll(() => [
    v3.getProduct.useQuery([productId], { select: (res) => res.data }),
    custom.getProductProgress.useQuery([productId, { apiVersion: "v1" }]),
  ])
}
