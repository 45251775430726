import { v3 } from "backoffice-api"
import { useModal, useRouteParamNumber } from "hooks"
import { Loader } from "materia"
import { QueryBoundary } from "utility-components"
import { custom } from "../../bonzai/bonzai"
import { createPlaythrough } from "../../bonzai/createPlaythrough"
import { ConfigError } from "../../tools/ConfigError"
import { useIframeMessages } from "../../tools/useIframeMessages"
import { ViewerModal } from "./ViewerModal"

type ProductType = v3["getProduct"]["data"]["product_type"]["identifier"]

type WebGLModalLoaderProps = {
  closeRoute: string
}
export const WebGLModalLoader = ({ closeRoute }: WebGLModalLoaderProps) => {
  const productId = useRouteParamNumber("productId")

  const { onClose } = useModal(closeRoute, () => {
    createPlaythrough(productId, {
      mutate: false,
      invalidate: true,
      invalidateDependencies: true,
    })
  })

  return (
    <ViewerModal>
      <QueryBoundary fallback={<Loader />}>
        <Load productId={productId} onClose={onClose} />
      </QueryBoundary>
    </ViewerModal>
  )
}

type LoadProps = {
  productId: number
  onClose: () => void
}
const Load = ({ productId, onClose }: LoadProps) => {
  const product = v3.getProduct.useQuery([productId], {
    select: (res) => res.data,
  })

  const webGLLink = custom.getWebGLLink.useQuery([product], {
    cacheTime: 0, // Transfer key can only be used once.
  })

  useIframeMessages({
    onClose,
    targetUrl: product.content_link,
  })

  if (!webGLLink) {
    throw new ConfigError(`No webGLLink for product ${productId}`)
  }

  return (
    <ViewerModal.WebGL
      src={webGLLink}
      orientation={getProductOrientation(product.product_type.identifier)}
      onClose={onClose}
    />
  )
}

const getProductOrientation = (type: ProductType) => {
  if (type === "skill") return "portrait"

  return "landscape"
}
