import { Navigate, useMatch } from "@tanstack/react-location"
import { v1, v3 } from "backoffice-api"
import { bonzai, type InferBonzai } from "bonzai"
import { getProductLink } from "../dataUtilities/productDataUtilities"

/**
 * This is custom logic for a specific company
 * Explanation: https://jira.attensi.com/browse/WEB-7439
 */
export const RedirectView = () => {
  const productIds = useProductIds()
  const product = api.getFirstPlayableProductByIds.useQuery([productIds])

  let to = "/for-you/"
  if (product) {
    to += getProductLink(product.id, product.product_type.identifier)
  }

  return <Navigate to={to} />
}

const useProductIds = () => {
  const { product_ids } = useMatch().search

  if (product_ids === undefined) {
    return []
  }

  if (typeof product_ids === "string") {
    return product_ids.split(",").map(Number)
  }

  if (typeof product_ids === "number") {
    return [product_ids]
  }

  throw new Error("product_ids is invalid " + product_ids)
}

const getFirstPlayableProductByIds = async (productIds: number[]) => {
  const playableIds = await v1.getPlayableProductIds.fetchQuery()
  const playableId = productIds.find((id) => playableIds.data.includes(id))
  if (!playableId) return null
  const { data: product } = await v3.getProduct.fetchQuery([playableId])
  return product
}

type api = InferBonzai<typeof api>
const api = bonzai({
  getFirstPlayableProductByIds,
})
