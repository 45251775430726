import checkIconCircleGreen from "assets/icons/checkmarkCircleGreen.svg"
import starFilled from "assets/icons/starFilled.svg"
import { Card } from "materia"
import type { PropsWithChildren } from "react"
import { modifiers } from "ui-tools"
import {
  FiestaImage,
  FiestaLink,
  type FiestaLinkProps,
} from "utility-components"
import s from "./JourneyTeaser.module.scss"

type Props = {
  link: FiestaLinkProps
  backgroundImage: string
  title: string
  certified: number
  certifiedMax: number
  stars: number
  starsMax: number
}

export const JourneyTeaser = (props: Props) => {
  const { link } = props

  return (
    <Card borderRadius="24">
      <FiestaLink className={modifiers(s, "teaser")} {...link}>
        <Image {...props} />
        <Gradient>
          <Title {...props} />
          <Stars {...props} />
          <Certified {...props} />
        </Gradient>
      </FiestaLink>
    </Card>
  )
}

const Image = ({ backgroundImage }: Props) => (
  <FiestaImage
    className={s.image}
    src={backgroundImage}
    alt=""
    sizes="(width <= medium) 100vw, 650px"
    height={175}
  />
)

const Gradient = ({ children }: PropsWithChildren) => (
  <div className={s.gradient}>{children}</div>
)

const Title = ({ title }: Props) => <h3 className={s.title}>{title}</h3>

const Stars = ({ stars, starsMax }: Props) => (
  <div className={s.stars} aria-hidden>
    <img className={s.stars__icon} src={starFilled} alt="" />
    {stars}/{starsMax}
  </div>
)

const Certified = ({ certified, certifiedMax }: Props) => (
  <div className={s.certified} aria-hidden>
    <img src={checkIconCircleGreen} className={s.certified__icon} alt="" />
    {certified}/{certifiedMax}
  </div>
)

const Skeleton = () => (
  <Card borderRadius="24">
    <div className={s.skeleton}>
      <div className={s.skeleton__title} />
      <div className={s.skeleton__status} />
    </div>
  </Card>
)

JourneyTeaser.Skeleton = Skeleton
