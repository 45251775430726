import {
  getIsLeaderboardProduct,
  getMaxStarsForProduct,
  getProductLink,
} from "../../dataUtilities/productDataUtilities"

import { v1, v3 } from "backoffice-api"
import { useQueryAll } from "bonzai"
import { useTranslation } from "react-i18next"
import { QueryBoundary } from "utility-components"
import { custom } from "../../bonzai/bonzai"
import { formatNumber } from "../../dataUtilities/formatNumber"
import { usePickText } from "../../i18n/usePickText"
import { FeaturedProducts } from "./FeaturedProducts"

type Category = v1["getProductCategories"]["data"][number]
type Product = v1["getProductsInCategory"]["data"][number]

type FeaturedProductsLoaderProps = {
  category: Category
}
export const FeaturedProductsLoader = (props: FeaturedProductsLoaderProps) => (
  <QueryBoundary fallback={<FeaturedProducts.Skeleton />}>
    <Load {...props} />
  </QueryBoundary>
)

const Load = ({ category }: FeaturedProductsLoaderProps) => {
  const products = useProducts(category.id)

  const productElements = products.map((product) => (
    <FeaturedProductLoader key={product.id} product={product} />
  ))

  return <FeaturedProducts>{productElements}</FeaturedProducts>
}

type FeaturedProductLoaderProps = {
  product: Product
}
const FeaturedProductLoader = ({ product }: FeaturedProductLoaderProps) => {
  const { i18n, t } = useTranslation()

  const pickText = usePickText()

  const [status, deadline] = useProductsData(product.id)

  return (
    <FeaturedProducts.Product
      link={{ to: getProductLink(product.id, product.identifier) }}
      image={product.image}
      title={pickText(product.titles)}
      isCertified={status?.certified ?? false}
      isMandatory={status?.is_mandatory ?? false}
      mandatoryText={t("user.MANDATORY")}
      starsMax={getMaxStarsForProduct(product.identifier, product.scorable)}
      stars={status?.stars ?? 0}
      isScoreVisible={getIsLeaderboardProduct(product.identifier)}
      scoreText={t("product.SCORE")}
      score={formatNumber(i18n.language, status?.high_score ?? 0)}
      isDue={deadline?.status === "due"}
      isOverdue={deadline?.status === "overdue"}
    />
  )
}

const useProductsData = (productId: number) => {
  return useQueryAll(() => [
    custom.getProductProgress.useQuery([productId, { apiVersion: "v1" }], {
      suspense: false,
    }),

    v3.getProductDeadline.useQuery([productId], {
      suspense: false,
      select: (res) => res?.data,
    }),
  ])
}

const useProducts = (category_id: number) => {
  const { i18n } = useTranslation()

  return v1.getProductsInCategory.useQuery(
    [{ category_id, locale: i18n.language, per_page: 20 }],
    { select: (res) => res.data }
  )
}
