import activityLeaderboardIcon from "assets/icons/activityLeaderboardIcon.svg"
import closeIcon from "assets/icons/closeIcon.svg"
import completedIcon from "assets/icons/completedIcon.svg"
import recentActivityIcon from "assets/icons/recentActivityIcon.svg"
import starFilled from "assets/icons/starFilled.svg"
import { Modal } from "materia"
import type { ReactNode } from "react"
import { modifiers } from "ui-tools"
import s from "./RecentActivityWidgetModal.module.scss"

export const RecentActivityWidgetModal = ({
  title,
  children,
  onClose,
}: {
  title: string
  children: ReactNode
  onClose: () => void
}) => {
  return (
    <Modal onClose={onClose} ariaLabel="Recent activity">
      <div className={s.card}>
        <div className={s.card__top}>
          <div className={s.card__topText}>
            <img className={s.card__icon} src={recentActivityIcon} alt="" />
            <h2 className={s.card__title}>{title}</h2>
          </div>
          <button className={s.closeButton} onClick={onClose}>
            <img className={s.closeButton__icon} src={closeIcon} alt="" />
          </button>
        </div>
        <div className={s.card__entries}>{children}</div>
      </div>
    </Modal>
  )
}

type EntryType = "stars" | "completed" | "leaderboard"

const Entry = ({
  image,
  name,
  timestamp,
  summaryText,
  productTitle,
  type,
}: {
  image: string
  name: string
  productTitle: string
  timestamp: string
  summaryText: string
  type: EntryType
}) => {
  const entryClass = modifiers(s, "entry", {
    orange: type === "leaderboard",
    green: type === "completed",
  })

  const getIcon = () => {
    if (type === "completed") return completedIcon
    if (type === "leaderboard") return activityLeaderboardIcon
    if (type === "stars") return starFilled
  }

  return (
    <div className={entryClass}>
      <img className={s.entry__avatar} src={image} alt="" />
      <div className={s.entry__name}>{name}</div>
      <div className={s.entry__time}>{timestamp}</div>

      <div className={s.entry__summary}>
        <img className={s.entry__icon} src={getIcon()} alt="" />
        <div className={s.entry__summaryText}>{summaryText}</div>
        <div className={s.entry__product}>{productTitle}</div>
      </div>
    </div>
  )
}

const SkeletonEntry = () => (
  <li className={s.skeletonEntry}>
    <div className={s.skeletonEntry__avatar} />
    <div className={s.skeletonEntry__title} />
    <div className={s.skeletonEntry__summary} />
  </li>
)

const Skeleton = () => (
  <Modal onClose={() => {}} ariaLabel="Loading recent activity">
    <div className={s.skeleton}>
      <div className={s.skeleton__top}>
        <div className={s.skeleton__icon} />
        <div className={s.skeleton__title} />
      </div>
      <ul className={s.skeleton__entries}>
        <SkeletonEntry />
        <SkeletonEntry />
        <SkeletonEntry />
      </ul>
    </div>
  </Modal>
)

RecentActivityWidgetModal.Entry = Entry
RecentActivityWidgetModal.Skeleton = Skeleton
