import { useTranslation } from "react-i18next"
import { getStaticLauncherLink } from "../../tools/getStaticLauncherLink"
import { ProductModalHelp } from "./ProductModalHelp"

type HelpModalProps = {
  onToggle: () => void
}
export const ProductModalHelpLoader = ({ onToggle }: HelpModalProps) => {
  const { t } = useTranslation()

  return (
    <ProductModalHelp onClose={onToggle}>
      <ProductModalHelp.CloseButton onClick={onToggle} />
      <ProductModalHelp.Items title={t("content.PRODUCT_WEBGL_LAUNCHER")}>
        <ProductModalHelp.Item>
          {t("content.PRODUCT_WEBGL_LAUNCHER_DESC.0")}
        </ProductModalHelp.Item>
        <ProductModalHelp.Item>
          {t("content.PRODUCT_WEBGL_LAUNCHER_DESC.1")}
        </ProductModalHelp.Item>
        <ProductModalHelp.Item>
          {t("content.PRODUCT_WEBGL_LAUNCHER_DESC.2")}
        </ProductModalHelp.Item>
      </ProductModalHelp.Items>

      <ProductModalHelp.Items title={t("content.PRODUCT_DESKTOP_LAUNCHER")}>
        <ProductModalHelp.Item>
          {t("content.PRODUCT_DESKTOP_LAUNCHER_DESC.0")}
          <ProductModalHelp.Link
            href={getStaticLauncherLink()}
            text={t("content.PRODUCT_DOWNLOAD_LAUNCHER")}
          />
        </ProductModalHelp.Item>
        <ProductModalHelp.Item>
          {t("content.PRODUCT_DESKTOP_LAUNCHER_DESC.1")}
        </ProductModalHelp.Item>
        <ProductModalHelp.Item>
          {t("content.PRODUCT_DESKTOP_LAUNCHER_DESC.2")}
        </ProductModalHelp.Item>
        <ProductModalHelp.Item>
          {t("content.PRODUCT_DESKTOP_LAUNCHER_DESC.3")}
        </ProductModalHelp.Item>
      </ProductModalHelp.Items>
    </ProductModalHelp>
  )
}
