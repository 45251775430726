import type { PropsWithChildren } from "react"
import { FiestaLink } from "utility-components"
import s from "./Navigation.module.scss"

export const Navigation = ({ children }: PropsWithChildren) => (
  <nav className={s.navigation}>{children}</nav>
)

type NavigationLinkProps = {
  href: string
  Icon: React.ElementType
  text: string
}
const Link = ({ href, text, Icon }: NavigationLinkProps) => (
  <FiestaLink
    to={href}
    search={{}}
    className={s.link}
    getActiveProps={getActiveProps}
  >
    <Icon className={s.link__icon} alt="" aria-label={text} />
    <div className={s.link__text}>{text}</div>
  </FiestaLink>
)

Navigation.Link = Link

const getActiveProps = () => ({
  className: s["link--active"],
  "aria-current": "page",
})
