import { create } from "zustand"

type BannerStore = {
  productId: number | undefined
  isVisible: boolean
  setProductId: (productId: number | undefined) => void
  setIsVisible: (isVisible: boolean) => void
}

export const useBannerStore = create<BannerStore>((set) => ({
  productId: undefined,
  isVisible: false,
  setProductId: (productId) => set({ productId }),
  setIsVisible: (isVisible) => set({ isVisible }),
}))
