import { useEffect, useRef } from "react"

export const useHasValueChanged = (
  value: boolean | string | number | undefined
) => {
  const enableAnimations = useRef(false)
  const initialValue = useRef(value)
  const hasChanged = initialValue.current !== value

  useEffect(() => {
    if (hasChanged) {
      enableAnimations.current = true
    }
  }, [hasChanged])

  return enableAnimations.current || hasChanged
}
