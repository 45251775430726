import { v1, v3 } from "backoffice-api"
import { getIsMandatory } from "../dataUtilities/productDataUtilities"

type GetProductProgressOptions = {
  apiVersion: "v1" | "v3"
}

type ProductProgress = {
  stars: number
  stars_requirement: number
  certified: boolean
  high_score: number
  is_mandatory: boolean
  play_count: number
  percentage: number
}

/**
 * Provides a unified api for v1 and v3. Useful during migration where both endpoints are used
 */
export const getProductProgress = async (
  productId: number,
  { apiVersion }: GetProductProgressOptions
) => {
  if (apiVersion === "v1") {
    return getProductProgressV1(productId)
  } else {
    return getProductProgressV3(productId)
  }
}

const getProductProgressV1 = async (productId: number) => {
  const { status } = await v1.getProductStatus.fetchQuery([productId])

  return {
    certified: status.certified ?? false,
    stars: status.stars,
    stars_requirement: status.certification_requirement,
    is_mandatory: getIsMandatory(status),
    play_count: status.play_count,
    high_score: status.high_score,
    percentage: status.percentage,
  } satisfies ProductProgress
}

const getProductProgressV3 = async (productId: number) => {
  const { data: progress } = await v3.getProductProgress.fetchQuery([productId])

  return {
    certified: progress.certified,
    stars: progress.stars_count,
    play_count: progress.play_count,
    high_score: progress.high_score,
    percentage: progress.percentage,
    is_mandatory: getIsMandatory(progress),
    stars_requirement: progress.certified_req,
  } satisfies ProductProgress
}
