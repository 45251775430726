import checkmarkCircleGreen from "assets/icons/checkmarkCircleGreen.svg"
import dueDateClock from "assets/icons/dueDateClock.svg"
import dueDateOverdue from "assets/icons/dueDateOverdue.svg"
import starEmpty from "assets/icons/starEmpty.svg"
import starFilled from "assets/icons/starFilled.svg"
import { times } from "lodash-es"
import { ProgressBar } from "materia"
import type { PropsWithChildren } from "react"
import { useTranslation } from "react-i18next"
import { modifiers } from "ui-tools"
import { FiestaLink, type FiestaLinkProps } from "utility-components"
import { ProductCard } from "../ProductCard/ProductCard"
import s from "./ProductCardActivity.module.scss"

type Props = {
  link: FiestaLinkProps
  info: string
  title: string
  completedCount: number
  totalCount: number
  starsMax: number

  isCompleted: boolean
  isCertified: boolean
  isMandatory: boolean
  isOverdue: boolean
  isDue: boolean

  mandatoryText: string
}

export const ProductCardActivity = (props: Props) => {
  const {
    link,
    info,
    title,
    completedCount,
    totalCount,
    isCompleted,
    isMandatory,
    isCertified,
    mandatoryText,
    starsMax,
  } = props
  return (
    <ProductCard.Shell>
      <FiestaLink
        className={modifiers(s, "card", { isCompleted, isCertified })}
        {...link}
      >
        <CertifiedOrDeadline {...props} />
        {isMandatory && <Mandatory>{mandatoryText}</Mandatory>}

        <div className={s.card__info}>{info}</div>
        <div className={s.card__progress}>
          {completedCount}/{totalCount}
          <ProgressBar
            value={completedCount}
            maxValue={totalCount}
            color={isCertified ? "green-30" : "blue-40"}
          />
        </div>
        <h3 className={s.card__title}>{title}</h3>
        <div className={s.card__stars}>
          <Stars starsMax={starsMax} stars={isCertified ? 1 : 0} />
        </div>
      </FiestaLink>
    </ProductCard.Shell>
  )
}

const CertifiedOrDeadline = ({ isDue, isOverdue, isCertified }: Props) => {
  if (isDue) return <DueDate />
  if (isOverdue) return <Overdue />
  if (isCertified) return <Certified />
}

const DueDate = () => {
  const { t } = useTranslation()
  return (
    <div className={modifiers(s, "deadline")}>
      <img className={s.deadline__icon} src={dueDateClock} alt="" />
      {t("product.DUE_DATE")}
    </div>
  )
}

const Overdue = () => {
  const { t } = useTranslation()
  return (
    <div className={modifiers(s, "deadline", "overdue")}>
      <img className={s.deadline__icon} src={dueDateOverdue} alt="" />
      {t("product.OVERDUE")}
    </div>
  )
}

const Certified = () => (
  <img className={s.certified} src={checkmarkCircleGreen} alt="" />
)

const Mandatory = ({ children }: PropsWithChildren) => (
  <div className={s.mandatory}>{children}</div>
)

type StarsProps = {
  stars: number
  starsMax: number
}
const Stars = ({ starsMax, stars }: StarsProps) => {
  const getSrc = (isFilled: boolean) => (isFilled ? starFilled : starEmpty)

  const starElements = times(starsMax, (index) => (
    <img src={getSrc(index < stars)} alt="" key={index} />
  ))

  return <div className={s.stars}>{starElements}</div>
}
