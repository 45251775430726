import { ERROR_NAME } from "./constants"
import type { QueryParams } from "./types"

type CreateFetchErrorProps = {
  message: string | undefined
  method: string
  url: string
  cause: Error | undefined
  status: number | undefined
  text: string | undefined
  json: unknown | undefined
}

export class FetchError extends Error {
  name = ERROR_NAME
  isOnline = window.navigator.onLine

  url: string
  scrubbedUrl: string
  method: string
  status: number | undefined
  params: QueryParams | undefined
  text: string | undefined
  json: unknown | undefined
  didRequestFail: boolean

  constructor(props: CreateFetchErrorProps) {
    const message = parseMessage(props)
    super(message, { cause: props.cause })

    this.didRequestFail = checkIfRequestFailed(props)
    this.url = props.url
    this.scrubbedUrl = scrubUrl(props.url)
    this.method = props.method
    this.status = props.status
    this.text = props.text
    this.json = props.json
    this.params = parseParams(props.url)
  }
}

const parseMessage = (props: CreateFetchErrorProps) => {
  const { message, url, status } = props
  const scrubbedUrl = scrubUrl(url)

  if (message === undefined) {
    return `Request failed. Status: ${status}. URL: ${scrubbedUrl}`
  }

  if (checkIfRequestFailed(props)) {
    return `Request failed. URL: ${scrubbedUrl}`
  }

  return message
}

const checkIfRequestFailed = ({ message, status }: CreateFetchErrorProps) => {
  const chromeMessage = "Failed to fetch"
  const safariMessage = "Load failed"

  return (
    message === chromeMessage ||
    message === safariMessage ||
    status === undefined
  )
}

const parseParams = (url: string | undefined) => {
  if (url === undefined) return undefined

  try {
    const entries = new URL(url).searchParams.entries()
    return Object.fromEntries(entries)
  } catch {
    return undefined
  }
}

const scrubUrl = (text: string) => {
  const numberSegment = /\/\d+/g
  const longSegment = /\/[^./]{20,}/g
  return text.replace(numberSegment, "/{number}").replace(longSegment, "/{...}")
}
