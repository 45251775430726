import { getSessionData } from "./api/sessionData"
import { transferKeyToAccessToken } from "./api/transferKey"
import { getConfig } from "./config"
import { loginNewSession } from "./login"
import { redirectToLogin } from "./redirect/redirectToLogin"
import {
  addSessionToStorage,
  getSessionsByEnvironment,
} from "./storage/sessionStorage"
import {
  addSubdomainToStorage,
  getSubdomainFromStorage,
} from "./storage/subdomainStorage"
import type { Session } from "./types/Session"

let sessionPromise: Promise<Session> | undefined = undefined

export const getActiveSession = async () => {
  if (sessionPromise) return sessionPromise

  sessionPromise = readActiveSession()

  const session = await sessionPromise
  addSubdomainToStorage(session.subdomain)
  return session
}

const readActiveSession = async (): Promise<Session> => {
  const {
    subdomain,
    rememberMe,
    transferKey,
    pickedLocale,
    impersonation,
    environment,
  } = await getConfig()

  if (transferKey) {
    const accessToken = await transferKeyToAccessToken(transferKey)
    if (accessToken) {
      return createNewSession({
        accessToken,
        rememberMe,
        pickedLocale,
        impersonation,
        environment,
      })
    }
  }

  const sessions = await getSessionsByEnvironment(environment)

  if (subdomain) {
    const session = sessions.find((session) => session.subdomain === subdomain)
    return session ?? loginWithSubdomain(subdomain)
  } else {
    const session = sessions.find(
      (session) => session.subdomain === getSubdomainFromStorage()
    )
    return session ?? sessions[0] ?? loginNewSession()
  }
}

type CreateNewSession = {
  accessToken: string
  rememberMe: boolean
  pickedLocale: string | undefined
  impersonation: boolean
  environment: string
}
const createNewSession = async ({
  accessToken,
  rememberMe,
  pickedLocale,
  impersonation,
  environment,
}: CreateNewSession): Promise<Session> => {
  const data = await getSessionData({ accessToken })
  const session: Session = {
    accessToken: accessToken,
    companyNameKey: data.companyNameKey,
    subdomain: data.subdomain,
    userId: data.userId,
    logo: data.logo,
    pickedLocale,
    impersonation,
    environment,
    rememberMe,
  }
  await addSessionToStorage(session)
  return session
}

const loginWithSubdomain = async (subdomain: string) => {
  return redirectToLogin({
    subdomain,
    redirectUrl: window.location.href,
  })
}
