import { modifiers } from "ui-tools"
import { toPercent } from "utils"
import s from "./LeaderboardEntry.module.scss"

type LeaderboardEntryProps = {
  image: string
  isFirst: boolean
  maxScore: number
  name: string
  rank: number
  score: number
  scoreFormatted: string
}
export const LeaderboardEntry = ({
  image,
  isFirst,
  maxScore,
  name,
  rank,
  score,
  scoreFormatted,
}: LeaderboardEntryProps) => (
  <div className={modifiers(s, "entry", { isFirst })}>
    <p className={s.entry__rank}>{rank}</p>
    <img
      loading="lazy"
      className={s.entry__image}
      src={image}
      alt="User icon"
    />
    <Progress
      isFirst={isFirst}
      name={name}
      score={score}
      scoreFormatted={scoreFormatted}
      maxScore={maxScore}
    />
  </div>
)

type ProgressProps = {
  isFirst: boolean
  maxScore: number
  name: string
  score: number
  scoreFormatted: string
}
const Progress = ({
  isFirst,
  maxScore,
  name,
  score,
  scoreFormatted,
}: ProgressProps) => (
  <div className={modifiers(s, "progress", { isFirst })}>
    <div className={s.progress__header}>
      <p className={s.progress__name}>{name}</p>
      <p className={s.progress__score}>{scoreFormatted}</p>
    </div>
    <div
      className={s.progress__line}
      style={{ width: `${toPercent(score, maxScore)}%` }}
    />
  </div>
)

const Skeleton = () => (
  <div className={s.skeleton}>
    <div className={s.skeleton__rank} />
    <div className={s.skeleton__image} />
    <div className={s.skeleton__progress} />
  </div>
)

LeaderboardEntry.Skeleton = Skeleton
