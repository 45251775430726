import { v3 } from "backoffice-api"
import { useQueryAll } from "bonzai"
import { formatDate } from "format"
import { useToggle } from "hooks"
import { useWindowWidth } from "materia"
import { useTranslation } from "react-i18next"
import { QueryBoundary } from "utility-components"
import { custom } from "../../bonzai/bonzai"
import { formatNumber } from "../../dataUtilities/formatNumber"
import {
  getIsPlayableV3,
  getMaxStarsForProduct,
} from "../../dataUtilities/productDataUtilities"
import { usePickText } from "../../i18n/usePickText"
import { ProductModal } from "./ProductModal"
import { ProductModalLoaderButtons } from "./ProductModalLoaderButtons"

type ProductModalMainLoaderProps = {
  productId: number
}
export const ProductModalLoader = (props: ProductModalMainLoaderProps) => (
  <QueryBoundary
    fallback={<ProductModal.Skeleton />}
    isSuspense={useWindowWidth().isBigWindow}
  >
    <Load {...props} />
  </QueryBoundary>
)

const Load = ({ productId }: ProductModalMainLoaderProps) => {
  const pickText = usePickText()
  const { t, i18n } = useTranslation()
  const [product, status, deadline] = useData(productId)
  const { product_type, no_score, description_long, title, product_image_url } =
    product
  const [isReadMoreVisible, toggleReadMore] = useToggle(false)
  const isPlayable = getIsPlayableV3(product)

  return (
    <ProductModal
      description={pickText(description_long, "")}
      image={product_image_url}
      productType={t(`product.PRODUCT_TYPES.${product_type.identifier}`)}
      score={formatNumber(i18n.language, status.high_score)}
      stars={status.stars}
      starsMax={getMaxStarsForProduct(product_type.identifier, !no_score)}
      starsRequirement={status.stars_requirement}
      title={pickText(title)}
      isCertified={status.certified}
      isMandatory={status.is_mandatory}
      isProductTypeVisible={!isPlayable}
      isReadMoreVisible={isReadMoreVisible}
      isScoreVisible={isPlayable && status.high_score > 0}
      mandatoryText={t("user.MANDATORY")}
      readMoreText={t("product.PRODUCT_READ_MORE")}
      scoreText={t("product.SCORE")}
      toCompleteText={t("product.TO_COMPLETE")}
      toggleReadMore={toggleReadMore}
      deadlineDate={formatDeadlineDate(deadline?.deadline_date, i18n.language)}
      isDue={deadline?.status === "due"}
      isOverdue={deadline?.status === "overdue"}
      isDeadlineCertified={
        deadline?.status === "certified" ||
        deadline?.status === "certified_after_deadline"
      }
    >
      <ProductModalLoaderButtons product={product} />
    </ProductModal>
  )
}

const formatDeadlineDate = (
  date: string | undefined | null,
  locale: string
) => {
  if (!date) return
  return formatDate(date, locale, {
    year: "numeric",
    month: "short",
    day: "numeric",
  })
}

const useData = (productId: number) => {
  return useQueryAll(() => [
    v3.getProduct.useQuery([productId, { include: ["locale_content_links"] }], {
      select: (res) => res.data,
    }),

    custom.getProductProgress.useQuery([productId, { apiVersion: "v1" }]),

    v3.getProductDeadline.useQuery([productId], {
      suspense: false,
      select: (res) => res?.data,
    }),
  ])
}
