import { v3 } from "backoffice-api"
import { TEXT_FALLBACK } from "ui-tools"

type User = {
  first_name?: string | null
  last_name?: string | null
  username?: string | null
}

export const useFormatUser = () => {
  const companySettings = v3.getCurrentCompanySettings.useQuery({
    select: (res) => res.data,
  })

  const formatUser = ({ username, first_name, last_name }: User): string => {
    if (username === "Anonomous") return username

    switch (companySettings.user_display_name_format) {
      case "username":
        return username || TEXT_FALLBACK
      case "firstname":
        return first_name || TEXT_FALLBACK
      case "firstname_and_lastname": {
        const fullName = [first_name, last_name].filter(Boolean).join(" ")
        return fullName || TEXT_FALLBACK
      }
    }
  }

  return formatUser
}
