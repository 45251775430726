import { auth } from "auth"
import { v3 } from "backoffice-api"
import { useModal, useRouteParamNumber } from "hooks"
import { Loader } from "materia"
import { useTranslation } from "react-i18next"
import { QueryBoundary } from "utility-components"
import { withQueryParams } from "utils"
import { createPlaythrough } from "../../bonzai/createPlaythrough"
import { usePickText } from "../../i18n/usePickText"
import { useIframeMessages } from "../../tools/useIframeMessages"
import { ViewerModal } from "./ViewerModal"

type WebGamesModalLoaderProps = {
  closeRoute: string
}
export const WebGamesModalLoader = ({
  closeRoute,
}: WebGamesModalLoaderProps) => {
  const productId = useRouteParamNumber("productId")

  const { onClose } = useModal(closeRoute, () => {
    createPlaythrough(productId, {
      mutate: false,
      invalidate: true,
      invalidateDependencies: true,
    })
  })

  return (
    <ViewerModal>
      <QueryBoundary fallback={<Loader />}>
        <Load productId={productId} onClose={onClose} />
      </QueryBoundary>
    </ViewerModal>
  )
}

type LoadProps = {
  productId: number
  onClose: () => void
}
const Load = ({ productId, onClose }: LoadProps) => {
  const pickText = usePickText()
  const { i18n } = useTranslation()

  const product = v3.getProduct.useQuery([productId], {
    select: (res) => res.data,
  })

  useIframeMessages({
    onClose,
    targetUrl: product.content_link,
  })

  const url = withQueryParams(product.content_link, { locale: i18n.language })

  const linkWithSession = auth.getLinkWithActiveSession.useQuery([{ url }], {
    cacheTime: 0,
  })

  return (
    <ViewerModal.FullScreen
      src={linkWithSession}
      headerText={pickText(product.title)}
      onClose={onClose}
    />
  )
}
