import { getEnv } from "env"
import config from "../server.config"

export const IS_DEV_BUILD = import.meta.env.DEV
export const IS_PROD_BUILD = import.meta.env.PROD
export const IS_TEST_BUILD = import.meta.env.MODE === "test"

export const {
  ADMIN_URL,
  API_TOKEN,
  APP_ENV,
  AUTH_URL,
  BACKOFFICE_API_URL,
  GIT_COMMIT_SHA,
  LEADERBOARD_API_URL,
  OPTIMIZER_URL,
  LOGIN_URL,
} = getEnv(config)
