import { useRef } from "react"
import { useOnClickedOutside } from "./useOnClickedOutside"
import { useOnEscape } from "./useOnEscape"
import { useToggle } from "./useToggle"

export const useMenu = (initialIsOpen = false) => {
  const [isOpen, toggleMenu, closeMenu] = useToggle(initialIsOpen)
  const containerRef = useRef<HTMLDivElement>(null)

  useOnEscape(closeMenu)
  useOnClickedOutside(containerRef, closeMenu)

  return { isOpen, toggleMenu, containerRef }
}
