import type { ReactNode } from "react"
import { getChromaticProps } from "ui-tools"
import { ProductCard } from "../ProductCard/ProductCard"
import s from "./ProductList.module.scss"

type ProductListProps = {
  children: ReactNode
  chromaticIgnore?: boolean
}
export const ProductList = ({
  children,
  chromaticIgnore,
}: ProductListProps) => (
  <div className={s.productList} {...getChromaticProps(chromaticIgnore)}>
    {children}
  </div>
)

type ShowMoreProps = {
  text: string
  onClick?: () => void
}
const ShowMore = ({ text, onClick }: ShowMoreProps) => (
  <ProductCard.Shell>
    <button className={s.showMore} onClick={onClick}>
      <div className={s.showMore__text}>{text}</div>
    </button>
  </ProductCard.Shell>
)

const Skeleton = () => {
  return (
    <ProductList>
      <ProductCard.Skeleton />
      <ProductCard.Skeleton />
      <ProductCard.Skeleton />
      <ProductCard.Skeleton />
      <ProductCard.Skeleton />
      <ProductCard.Skeleton />
    </ProductList>
  )
}

ProductList.ShowMore = ShowMore
ProductList.Skeleton = Skeleton
