import { setContext } from "@sentry/react"
import * as sentry from "sentry"
import { APP_ENV, GIT_COMMIT_SHA, IS_PROD_BUILD } from "../env"
import { APP_TYPE, APP_VERSION, IS_APP } from "../vuplex/constants"

const SENTRY_URL =
  "https://2ce3968eb57f430285a2f708d811ef15@o229568.ingest.sentry.io/4504684345032704"

export const initializeSentry = async () => {
  if (!GIT_COMMIT_SHA) return

  await sentry.initializeSentry({
    isEnabled: IS_PROD_BUILD,
    sentryUrl: SENTRY_URL,
    environment: APP_ENV,
    release: GIT_COMMIT_SHA,
  })

  setContext("App", {
    "Is in app mode?": IS_APP,
    "App type": APP_TYPE,
    "App version": APP_VERSION,
  })
}
