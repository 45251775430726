import { createElement } from "react"
import s from "./styles.module.scss"

export const HiddenHeader = ({
  title,
  tag,
}: {
  title: string
  tag: "h1" | "h2" | "h3" | "h4" | "h5" | "h6"
}) => {
  return createElement(tag, { className: s.hiddenHeader }, title)
}
