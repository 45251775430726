import { ReactComponent as MagicStar } from "assets/icons/starMagic.svg"
import journeyUnlockedSunrise from "assets/illustrations/journeyUnlockedSunrise.svg"
import { times } from "lodash-es"
import { Button, Modal } from "materia"
import { backgroundImageStyle } from "ui-tools"
import { FiestaImage } from "utility-components"
import s from "./JourneyUnlockedModal.module.scss"

type JourneyUnlockedModalProps = {
  onContinue: () => void
  onClose: () => void
  modalTitle: string
  journeyTitle: string
  journeyImage: string
  buttonTitle: string
}

export const JourneyUnlockedModal = ({
  onContinue,
  onClose,
  modalTitle,
  journeyTitle,
  journeyImage,
  buttonTitle,
}: JourneyUnlockedModalProps) => (
  <Modal
    onClose={onClose}
    overflowAuto={false}
    ariaLabel="Unlocked new journey"
  >
    <div className={s.journeyUnlockedModal}>
      <MagicStars />
      <div
        className={s.journeyUnlockedModal__cover}
        style={backgroundImageStyle(journeyUnlockedSunrise)}
      >
        <FiestaImage
          sizes="90px"
          className={s.journeyUnlockedModal__coverJourneyThumbnail}
          src={journeyImage}
          alt=""
        />
      </div>
      <div className={s.journeyUnlockedModal__content}>
        <h3 className={s.journeyUnlockedModal__contentModalTitle}>
          {modalTitle}
        </h3>
        <p className={s.journeyUnlockedModal__contentJourneyTitle}>
          {journeyTitle}
        </p>
        <Button onClick={onContinue} borderRadius={"32"}>
          {buttonTitle}
        </Button>
      </div>
    </div>
  </Modal>
)

const MagicStars = () => {
  const NUMBER_OF_STARS = 10 // Manually adjusted in CSS file
  return times(NUMBER_OF_STARS, (index) => (
    <MagicStar className={s.journeyUnlockedModal__star} key={index} />
  ))
}
