import checkmark from "assets/icons/checkmark.svg"
import chevronIconWhite from "assets/icons/chevronIconWhite.svg"
import closeIcon from "assets/icons/closeIconWhite.svg"
import descriptionIcon from "assets/icons/journeyDescriptionIcon.svg"
import lockedJourneyIcon from "assets/icons/lockedJourneyIcon.svg"
import { useHasValueChanged } from "hooks"
import { times } from "lodash-es"
import { ProgressCircle } from "materia"
import { Children, type PropsWithChildren, type ReactNode } from "react"
import { backgroundImageStyle, modifiers, sanitizeHtml } from "ui-tools"
import { toPercent } from "utils"
import { JourneyCard } from "../JourneyCard/JourneyCard"
import s from "./Journey.module.scss"

type JourneyProps = {
  backgroundImage: string
  isLocked: boolean
  isDescriptionOpen: boolean | undefined
  onClick: () => void
  children: ReactNode
}
export const Journey = ({
  backgroundImage,
  isLocked,
  isDescriptionOpen,
  onClick,
  children,
}: JourneyProps) => {
  const enableAnimations = useHasValueChanged(isLocked)
  return (
    <div
      className={modifiers(s, "journey", { isLocked, enableAnimations })}
      style={backgroundImageStyle(backgroundImage)}
    >
      <div
        className={modifiers(s, "journey__overlay", { isDescriptionOpen })}
        onClick={onClick}
      />
      {children}
    </div>
  )
}

type HeaderProps = {
  title: string
  isOpen: boolean
  isLocked: boolean
  isCertified: boolean
  progress: number
  progressMax: number
  lockedByTitle: string | undefined
  onClick: () => void
}
const Header = ({
  title,
  isOpen,
  isLocked,
  isCertified,
  progress,
  progressMax,
  lockedByTitle,
  onClick,
}: HeaderProps) => {
  const enableAnimations = useHasValueChanged(isLocked)

  return (
    <div className={modifiers(s, "header", { isOpen, enableAnimations })}>
      <button
        className={s.header__button}
        onClick={onClick}
        title="Toggle journey"
      >
        <div className={s.header__status}>
          {progressMax === 0 ? (
            <div className={s.header__progress} />
          ) : isCertified ? (
            <img className={s.header__certified} src={checkmark} alt="" />
          ) : (
            <div className={s.header__progress}>
              <ProgressCircle
                color="white"
                backgroundColor="black"
                fillBackground
                value={progress}
                maxValue={progressMax}
                stroke={7}
              >
                {toPercent(progress, progressMax)}%
              </ProgressCircle>
            </div>
          )}
          <div
            className={modifiers(s, "header__lockedContainer", {
              isLocked,
              enableAnimations,
            })}
          >
            <img
              title={
                lockedByTitle
                  ? `Complete ${lockedByTitle} to unlock journey`
                  : undefined
              }
              className={"header__lockedContainerIcon"}
              src={lockedJourneyIcon}
              alt=""
            />
          </div>
        </div>

        <h2 className={s.header__title}>{title}</h2>
        <img className={s.header__toggle} src={chevronIconWhite} alt="" />
      </button>
    </div>
  )
}

type ContentProps = {
  isOpen: boolean
  children: ReactNode
}
const Content = ({ isOpen, children }: ContentProps) => {
  if (!isOpen) return null
  return <div className={s.content}>{children}</div>
}

type JourneyDescriptionIconProps = {
  onClick: () => void
  isDescriptionOpen: boolean
  isLocked: boolean
  journeyDescriptionText: string | undefined
}
const JourneyDescriptionIcon = ({
  onClick,
  isDescriptionOpen,
  isLocked,
  journeyDescriptionText,
}: JourneyDescriptionIconProps) => {
  if (!journeyDescriptionText && !isLocked) return

  const icon = isDescriptionOpen ? closeIcon : descriptionIcon

  return (
    <div
      className={modifiers(s, "journeyDescriptionIcon", {
        isDescriptionOpen,
      })}
    >
      <button onClick={onClick} title="Toggle journey description">
        <div className={s.journeyDescriptionIcon__background}>
          <img className={s.journeyDescriptionIcon__image} src={icon} alt="" />
        </div>
      </button>
    </div>
  )
}

type JourneyDescriptionProps = {
  journeyDescriptionImage: string | undefined
  journeyDescriptionText: string | undefined
  isDescriptionOpen: boolean
  isLocked: boolean
  firstLockedByJourneyText: string
  lockedByJourneyTitle: string | undefined
  lastLockedByJourneyText: string
}
const JourneyDescription = ({
  journeyDescriptionImage,
  journeyDescriptionText,
  isDescriptionOpen,
  isLocked,
  firstLockedByJourneyText,
  lockedByJourneyTitle,
  lastLockedByJourneyText,
}: JourneyDescriptionProps) => {
  if (!isDescriptionOpen) return

  return (
    <dialog className={s.journeyDescription} aria-label="Journey description">
      {journeyDescriptionText && (
        <JourneyDescriptionTop
          journeyDescriptionImage={journeyDescriptionImage}
          journeyDescriptionText={journeyDescriptionText}
        />
      )}

      {journeyDescriptionText && isLocked && (
        <div className={s.journeyDescription__line} />
      )}

      {isLocked && (
        <JourneyDescriptionLocked
          firstLockedByJourneyText={firstLockedByJourneyText}
          lockedByJourneyTitle={lockedByJourneyTitle}
          lastLockedByJourneyText={lastLockedByJourneyText}
        />
      )}
    </dialog>
  )
}

type JourneyDescriptionTopProps = {
  journeyDescriptionImage: string | undefined
  journeyDescriptionText: string
}
const JourneyDescriptionTop = ({
  journeyDescriptionImage,
  journeyDescriptionText,
}: JourneyDescriptionTopProps) => {
  return (
    <div className={s.journeyDescriptionTop}>
      {journeyDescriptionImage && (
        <img
          src={journeyDescriptionImage}
          className={s.journeyDescriptionTop__image}
          alt=""
        />
      )}
      <div
        className={s.journeyDescriptionTop__text}
        dangerouslySetInnerHTML={sanitizeHtml(
          journeyDescriptionText,
          "allow-links"
        )}
      />
    </div>
  )
}

type JourneyDescriptionLockedProps = {
  firstLockedByJourneyText: string
  lockedByJourneyTitle: string | undefined
  lastLockedByJourneyText: string
}
const JourneyDescriptionLocked = ({
  firstLockedByJourneyText,
  lockedByJourneyTitle,
  lastLockedByJourneyText,
}: JourneyDescriptionLockedProps) => (
  <div className={s.journeyDescriptionLocked}>
    {lockedByJourneyTitle && (
      <div className={s.journeyDescriptionLocked__lockedByTextWrapper}>
        <div>{firstLockedByJourneyText}</div>
        <div className={s.journeyDescriptionLocked__lockedByTitle}>
          {lockedByJourneyTitle}
        </div>
        <div>{lastLockedByJourneyText}</div>
      </div>
    )}
  </div>
)

const Products = ({ children }: PropsWithChildren) => {
  return (
    <div>
      <div className={s.startBlock} />
      <div>
        {Children.map(children, (child) => (
          <JourneyCardBlock>{child}</JourneyCardBlock>
        ))}
      </div>
    </div>
  )
}

type SkeletonProps = {
  isOpen: boolean
}
const Skeleton = ({ isOpen }: SkeletonProps) => (
  <div className={s.skeleton}>
    <div className={s.skeleton__header}>
      <div className={s.skeleton__headerButton}>
        <div className={s.skeleton__progress} />
        <div className={s.skeleton__title} />
        <div className={s.skeleton__toggle} />
      </div>
    </div>

    <Journey.Content isOpen={isOpen}>
      <div className={s.startBlock} />
      <div>
        {times(6, (index) => (
          <JourneyCardBlock key={index}>
            <JourneyCard.Skeleton />
          </JourneyCardBlock>
        ))}
      </div>
    </Journey.Content>
  </div>
)

Journey.Header = Header
Journey.Content = Content
Journey.JourneyDescriptionIcon = JourneyDescriptionIcon
Journey.JourneyDescription = JourneyDescription
Journey.Products = Products
Journey.Skeleton = Skeleton

const JourneyCardBlock = ({ children }: PropsWithChildren) => (
  <div className={s.journeyBlock}>
    <div>{children}</div>
  </div>
)
