import newsColorIcon from "assets/icons/newsColorIcon.svg"
import { Card } from "materia"
import type { ReactNode } from "react"
import { sanitizeHtml } from "ui-tools"
import {
  FiestaImage,
  FiestaLink,
  type FiestaLinkProps,
} from "utility-components"
import s from "./NewsWidget.module.scss"

type NewsWidgetProps = {
  children: ReactNode
  headerText: string
}
export const NewsWidget = ({ children, headerText }: NewsWidgetProps) => (
  <Card borderRadius="24">
    <div className={s.widget}>
      <div className={s.widget__header}>
        <img className={s.widget__image} src={newsColorIcon} alt="" />
        <h2 className={s.widget__title}>{headerText}</h2>
      </div>
      <div className={s.widget__items}>{children}</div>
    </div>
  </Card>
)

type ItemProps = {
  link: FiestaLinkProps
  title: string
  description: string
  image: string
}
const Item = ({ link, title, image, description }: ItemProps) => (
  <FiestaLink {...link} className={s.item}>
    <h3 className={s.item__title}>{title}</h3>
    <FiestaImage className={s.item__image} src={image} alt="" sizes="60px" />
    <div
      className={s.item__description}
      dangerouslySetInnerHTML={sanitizeHtml(description, "strict")}
    />
  </FiestaLink>
)

const Skeleton = () => {
  const newsItem = (
    <li className={s.skeleton__item}>
      <div className={s.skeleton__itemTitle} />
      <div className={s.skeleton__itemImage} />
      <div className={s.skeleton__itemDescription} />
    </li>
  )
  return (
    <Card borderRadius="24">
      <div className={s.skeleton}>
        <div className={s.skeleton__header}>
          <div className={s.skeleton__headerImage} />
          <div className={s.skeleton__headerTitle} />
        </div>

        <ul className={s.skeleton__list}>
          {newsItem}
          {newsItem}
          {newsItem}
        </ul>
      </div>
    </Card>
  )
}

type EmptyProps = {
  emptyText: string
}
const Empty = ({ emptyText }: EmptyProps) => (
  <div className={s.empty}>{emptyText}</div>
)

NewsWidget.Item = Item
NewsWidget.Skeleton = Skeleton
NewsWidget.Empty = Empty
