import clockIcon from "assets/icons/clockIcon.svg"
import helpIcon from "assets/icons/helpIcon.svg"
import { ReactComponent as SuccessIcon } from "assets/icons/successIcon.svg"
import { Button, Tooltip } from "materia"
import type { PropsWithChildren, ReactNode } from "react"
import { useTranslation } from "react-i18next"
import { modifiers } from "ui-tools"
import { ProductModalShell } from "../ProductModalShell/ProductModalShell"
import s from "./ProductModalActivity.module.scss"

export const ProductModalActivity = ({ children }: PropsWithChildren) => (
  <ProductModalShell.Side>
    <div className={s.modal}>{children}</div>
  </ProductModalShell.Side>
)

type TopProps = {
  completedCount: number
  totalCount: number
  isLocked: boolean
}
const Top = ({ completedCount, totalCount, isLocked }: TopProps) => {
  const { t } = useTranslation()
  return (
    <div
      className={modifiers(s, "top", {
        isCompleted: completedCount >= totalCount,
        isLocked,
      })}
    >
      <h3
        aria-live="polite"
        aria-label={t("activity.ACTIVITY_USER_STATUS_COMPLETION_RATE", {
          value: completedCount,
          total: totalCount,
        })}
        className={s.top__progressFraction}
      >
        {completedCount}/{totalCount}
      </h3>
      <ProgressBar
        value={completedCount}
        maxValue={totalCount}
        isLocked={isLocked}
      />
    </div>
  )
}

const Tasks = ({ children }: PropsWithChildren) => (
  <div className={s.tasks} aria-label="activities" role="group" tabIndex={0}>
    {children}
  </div>
)

type TaskProps = {
  title: string
  isChecked: boolean
  isLocked: boolean
  onClick: () => void
}
const Task = ({ title, isChecked, isLocked, onClick }: TaskProps) => (
  <label className={modifiers(s, "task", { isLocked, isChecked })}>
    <div className={s.task__icon}>
      {(isChecked || isLocked) && <SuccessIcon />}
    </div>
    {title}
    <input
      onChange={onClick}
      type="checkbox"
      disabled={isLocked}
      className={s.task__input}
    />
  </label>
)

type BottomProps = {
  isVisible: boolean
  children: ReactNode
}
const Bottom = ({ isVisible, children }: BottomProps) => {
  if (!isVisible) return null

  return (
    <ProductModalShell.ActionButtons>
      <div className={s.bottom} aria-live="polite">
        {children}
      </div>
    </ProductModalShell.ActionButtons>
  )
}

type ConfirmButtonProps = {
  confirmText: string
  onClick: () => void
  isVisible: boolean
}
const ConfirmButton = ({
  isVisible,
  confirmText,
  onClick,
}: ConfirmButtonProps) => {
  if (!isVisible) return null

  return (
    <Button borderRadius="12" size="big" onClick={onClick}>
      {confirmText}
    </Button>
  )
}

// TODO: Waiting for approval looks bad on mobile. Background isn't filled
type WaitingForApprovalProps = {
  title: string
  tooltipTitle: string
  tooltipDescription: string
  isVisible: boolean
}
const WaitingForApproval = ({
  title,
  tooltipTitle,
  tooltipDescription,
  isVisible,
}: WaitingForApprovalProps) => {
  if (!isVisible) return null

  return (
    <div className={s.waitingForApproval}>
      <img className={s.waitingForApproval__clock} src={clockIcon} alt="" />
      <div className={s.waitingForApproval__title}>{title}</div>
      <div className={s.waitingForApproval__help}>
        <HelpTooltip title={tooltipTitle} description={tooltipDescription} />
      </div>
    </div>
  )
}

type HelpTooltipProps = {
  title: string
  description: string
}
const HelpTooltip = ({ title, description }: HelpTooltipProps) => (
  <Tooltip position="top">
    <Tooltip.Trigger>
      <img className={s.helpTooltip__helpIcon} src={helpIcon} alt="Help" />
    </Tooltip.Trigger>
    <Tooltip.Content>
      <div className={s.helpTooltip__content}>
        <div className={s.helpTooltip__title}>{title}</div>
        <div className={s.helpTooltip__description}>{description}</div>
      </div>
    </Tooltip.Content>
  </Tooltip>
)

const Skeleton = () => (
  <ProductModalShell.Side>
    <div className={s.skeleton} />
  </ProductModalShell.Side>
)

ProductModalActivity.Top = Top
ProductModalActivity.Tasks = Tasks
ProductModalActivity.Task = Task
ProductModalActivity.Bottom = Bottom
ProductModalActivity.ConfirmButton = ConfirmButton
ProductModalActivity.WaitingForApproval = WaitingForApproval
ProductModalActivity.Skeleton = Skeleton

type ProgressBarProps = {
  value: number
  isLocked: boolean
  maxValue?: number
}
const ProgressBar = ({ value, maxValue = 100, isLocked }: ProgressBarProps) => (
  <div className={modifiers(s, "progressBar", { isLocked })}>
    <div
      className={s.progressBar__progress}
      style={{ transform: `scaleX(${value / maxValue})` }}
    />
  </div>
)
