import { v1 } from "backoffice-api"
import { useModal } from "hooks"
import type { TFunction } from "i18next"
import { useWindowWidth } from "materia"
import { useTranslation } from "react-i18next"
import { TEXT_FALLBACK } from "ui-tools"
import { QueryBoundary } from "utility-components"
import { exists, getEntries } from "utils"
import { useGetOrdinal } from "../../dataUtilities/getOrdinal"
import { usePickText } from "../../i18n/usePickText"
import { RecentActivityWidgetModal } from "./RecentActivityWidgetModal"

type ActivityFeed = v1["getActivityFeed"]
type ActivityItem = ReturnType<typeof useData>[number]

export const RecentActivityWidgetModalLoader = ({
  closeRoute,
}: {
  closeRoute: string
}) => {
  const { isBigWindow } = useWindowWidth()
  if (isBigWindow) return null

  return (
    <QueryBoundary fallback={<RecentActivityWidgetModal.Skeleton />}>
      <Load closeRoute={closeRoute} />
    </QueryBoundary>
  )
}

const Load = ({ closeRoute }: { closeRoute: string }) => {
  const { t } = useTranslation()
  const getSummaryText = useGetSummaryText()
  const pickText = usePickText()
  const { onClose } = useModal(closeRoute)

  const activityItems = useData()

  const cards = activityItems.map((item, index) => {
    return (
      <RecentActivityWidgetModal.Entry
        key={index}
        type={item.type}
        image={item.image}
        name={item.first_name ?? TEXT_FALLBACK}
        productTitle={pickText(item.product)}
        timestamp={displayTimestamp(item.timestamp, t)}
        summaryText={getSummaryText(item)}
      />
    )
  })

  return (
    <RecentActivityWidgetModal
      title={t("activityFeed.activity")}
      onClose={onClose}
    >
      {cards}
    </RecentActivityWidgetModal>
  )
}

const useData = () => {
  return v1.getActivityFeed.useQuery({
    select: selectActivityFeed,
  })
}

const selectActivityFeed = (feed: ActivityFeed) => {
  return getEntries(feed)
    .map(([type, item]) => ("user_id" in item ? { ...item, type } : undefined))
    .filter(exists)
}

const displayTimestamp = (timestamp: string, t: TFunction) => {
  const time = new Date(timestamp)
  const now = new Date()
  const difference = now.getTime() - time.getTime()

  const ONE_HOUR = 1000 * 60 * 60
  const ONE_DAY = ONE_HOUR * 24
  const THREE_DAYS = ONE_DAY * 3

  const hours = Math.floor(difference / ONE_HOUR)
  const days = Math.floor(difference / ONE_DAY)

  if (difference < ONE_HOUR) return t("date.JUST_NOW")
  if (difference < ONE_DAY) return hours + t("date.HOUR_SHORT")
  if (difference < THREE_DAYS) return days + t("date.DAY_SHORT")
  return t("date.A_WHILE_AGO")
}

const useGetSummaryText = () => {
  const { t } = useTranslation()
  const getOrdinal = useGetOrdinal()

  return (item: ActivityItem) => {
    const { type, new_rank, new_stars } = item
    const rank = Number(new_rank)
    const stars = Number(new_stars)

    if (type === "stars") return t("activityFeed.stars", { count: stars })
    if (type === "leaderboard") return getOrdinal(rank)
    return t("activityFeed.completed")
  }
}
