import { createAppLauncherButton } from "@attensi/app-launcher"
import { useEffect, useRef } from "react"

export const AppLauncherButton = () => {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (ref.current) {
      createAppLauncherButton(ref.current)
    }
  }, [])

  return <div ref={ref} />
}
