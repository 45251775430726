import { getConfig } from "../config"
import { redirectTo } from "./redirectTo"

type Options = {
  subdomain: string | undefined
  redirectUrl?: string
}

export const redirectToLogin = async ({ subdomain, redirectUrl }: Options) => {
  const { loginUrl, redirectStrategy, locale } = await getConfig()
  if (!redirectUrl) {
    const { href, origin } = window.location
    redirectUrl = redirectStrategy === "href" ? href : origin
  }

  return redirectTo(loginUrl, {
    subdomain: subdomain,
    pick_company: true,
    locale: locale,
    transfer_key: true,
    reset: true,
    // Login doesn't parse query params correctly, so redirect must be the last parameter.
    redirect: redirectUrl,
  })
}
