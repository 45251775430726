import { v1 } from "backoffice-api"
import { useModal, useRouteParamNumber } from "hooks"
import { Loader } from "materia"
import { useTranslation } from "react-i18next"
import { QueryBoundary } from "utility-components"
import { IS_APP } from "../../vuplex/constants"
import { sendVuplexMessage } from "../../vuplex/sendVuplexMessage"
import { VppModal } from "./VppModal"

type VppModalLoaderProps = {
  closeRoute: string
}
export const VppModalLoader = ({ closeRoute }: VppModalLoaderProps) => {
  const productId = useRouteParamNumber("productId")

  const { t } = useTranslation()
  const { onClose } = useModal(closeRoute)

  return (
    <VppModal onClose={onClose}>
      <VppModal.Header
        title={t("product.PRODUCT_VPP_SELECT_COUNTRY")}
        onClose={onClose}
      />
      <VppModal.BodyText text={t("product.PRODUCT_VPP_SELECT_COUNTRY_DESC")} />
      <QueryBoundary fallback={<Loader delay={200} />}>
        <Countries productId={productId} />
      </QueryBoundary>
    </VppModal>
  )
}

type CountriesProps = {
  productId: number
}
const Countries = ({ productId }: CountriesProps) => {
  const countries = v1.getVppCountries.useQuery([productId])

  const countryElements = countries.map((country) => (
    <VppModal.Country
      title={country.country}
      onClick={() => getVppCode(productId, country.store)}
      key={country.country}
    />
  ))

  return <VppModal.Countries>{countryElements}</VppModal.Countries>
}

const getVppCode = async (productId: number, store: string) => {
  const { code } = await v1.getVppCode(productId, store)

  const url = getUrlFromCode(code)

  if (IS_APP) {
    openInVuplex(url)
  } else {
    document.location.replace(url)
  }
}

const openInVuplex = (link: string) => {
  sendVuplexMessage({
    type: "OPEN_LINK",
    payload: {
      link,
      openExternalBrowser: true,
    },
  })
}

const getUrlFromCode = (code: string) => {
  const isUrl = code.startsWith("https")
  if (isUrl) return code

  return `https://apps.apple.com/redeem?code=${code}&ctx=apps`
}
