import type { SourceConfig } from "../types/SourceConfig"

export const SOURCE_CONFIG = [
  {
    name: "webcontent",
    baseUrl: "https://webcontent.attensi.com",
  },
  {
    name: "s3-portal-production",
    baseUrl: "https://attensi-portal-production.s3.eu-central-1.amazonaws.com",
  },
  {
    name: "backend",
    baseUrl: "https://backend.attensi.com",
  },
  {
    name: "api",
    baseUrl: "https://api.attensi.com",
  },
  {
    name: "api-staging",
    baseUrl: "https://api-staging.attensi.com",
  },
] as const satisfies SourceConfig
