import { useEffect, type RefObject } from "react"

export const useOnClickedOutside = <T extends HTMLElement>(
  insideRef: RefObject<T>,
  callback: () => void
) => {
  useEffect(() => {
    const onDocumentClick = (e: MouseEvent) => {
      const element = insideRef.current
      if (!element) return

      const isInside = element.contains(e.target as Node)
      if (isInside) return

      callback()
    }

    document.addEventListener("click", onDocumentClick, { capture: true })
    return () => {
      document.removeEventListener("click", onDocumentClick, { capture: true })
    }
  }, [insideRef, callback])
}
