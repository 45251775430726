import { v1 } from "backoffice-api"
import { useQueryParam, useScrollToTopOnMount, type SetQueryParam } from "hooks"
import { debounce, times } from "lodash-es"
import { useMemo, type ReactNode } from "react"
import { useTranslation } from "react-i18next"
import { QueryBoundary, WhenVisible } from "utility-components"
import { getNextPageParamV1 } from "../../bonzai/getNextPageParamV1"
import { getPaginationDataV1 } from "../../dataUtilities/getPaginationDataV1"
import { usePickText } from "../../i18n/usePickText"
import { HiddenHeader } from "../HiddenHeader"
import { ProductCardLoader } from "../ProductCard/ProductCardLoader"
import { ProductCardSkeletons } from "../ProductCard/ProductCardSkeletons"
import { ProductList } from "../ProductList/ProductList"
import { ExploreView } from "./ExploreView"

type ProductType = v1["getProduct"]["product_type"]

const ITEMS_PER_PAGE = 18

export const ExploreViewLoader = () => {
  useScrollToTopOnMount()

  const { t } = useTranslation()

  const [search, setSearch] = useQueryParam("search")
  const [activeType, setActiveType] = useQueryParam<ProductType>("product_type")

  return (
    <ExploreView>
      <HiddenHeader title={t(`navigation.EXPLORE`)} tag="h1" />
      <Search search={search} setSearch={setSearch} />
      <QueryBoundary fallback={<FiltersSkeleton />}>
        <FiltersLoader activeType={activeType} setActiveType={setActiveType} />
      </QueryBoundary>
      <HiddenHeader title={t(`product.PRODUCTS`)} tag="h2" />
      <QueryBoundary fallback={<ProductsSkeleton />}>
        <ExploreProductsLoader activeType={activeType} searchQuery={search} />
      </QueryBoundary>
    </ExploreView>
  )
}

type SearchProps = {
  search: string | undefined
  setSearch: SetQueryParam<string>
}
const Search = ({ search, setSearch }: SearchProps) => {
  const { t } = useTranslation()

  const debouncedOnSearch = useMemo(() => debounce(setSearch, 500), [setSearch])

  const onKeyDown = (key: string, value: string) => {
    if (key === "Enter") setSearch(value)
  }

  return (
    <ExploreView.Search
      defaultValue={search}
      placeholder={t("searchbar.PLACEHOLDER")}
      onKeyDown={(e) => onKeyDown(e.key, e.currentTarget.value)}
      onChange={(e) => debouncedOnSearch(e.currentTarget.value)}
    />
  )
}

type FiltersLoaderProps = {
  activeType: ProductType | undefined
  setActiveType: SetQueryParam<ProductType>
}
const FiltersLoader = ({ activeType, setActiveType }: FiltersLoaderProps) => {
  const { t } = useTranslation()

  const { data: productTypes } = v1.getExploreProductTypes.useQuery()

  const filterElements = productTypes?.map((type) => (
    <ExploreView.Filter
      key={type}
      isActive={activeType === type}
      onClick={() => setActiveType(type === activeType ? undefined : type)}
      text={t(`product.PRODUCT_TYPES.${type}`)}
    />
  ))

  return <ExploreView.Filters>{filterElements}</ExploreView.Filters>
}

const FiltersSkeleton = () => {
  const skeletonFilters = times(5, (index) => (
    <ExploreView.FilterSkeleton key={index} />
  ))
  return <ExploreView.Filters>{skeletonFilters}</ExploreView.Filters>
}

type ExploreProductsLoaderProps = {
  activeType: ProductType | undefined
  searchQuery: string | undefined
}
const ExploreProductsLoader = ({
  activeType,
  searchQuery,
}: ExploreProductsLoaderProps) => {
  const { t } = useTranslation()

  const pickText = usePickText()
  const productsData = useData(activeType, searchQuery)
  const { data, fetchNextPage, isFetching } = productsData

  const isEmpty = data.pages[0]?.data.length === 0
  if (isEmpty) return <div>{t("notFound.NOTFOUND_SHORT")}</div>

  const { nextPageCount } = getPaginationDataV1(data)
  const skeletonCount = isFetching ? nextPageCount : 0
  const products = data.pages.flatMap((page) => page.data)
  const productCards = products.map((product) => (
    <ProductCardLoader
      key={product.id}
      productId={product.id}
      productImage={product.image}
      productTitle={pickText(product.titles)}
      productType={product.identifier}
      isScorable={product.scorable}
      apiVersion="v1"
    />
  ))

  return (
    <>
      <ProductsSkeleton count={skeletonCount}>{productCards}</ProductsSkeleton>
      <WhenVisible
        key={`${activeType} ${searchQuery} ${data.pages.length}`}
        whenVisible={fetchNextPage}
      />
    </>
  )
}

type ProductsSkeletonProps = {
  count?: number
  children?: ReactNode
}
const ProductsSkeleton = ({
  children,
  count = ITEMS_PER_PAGE,
}: ProductsSkeletonProps) => (
  <ProductList>
    {children}
    <ProductCardSkeletons count={count} />
  </ProductList>
)

const useData = (
  identifier: ProductType | undefined,
  search: string | undefined
) => {
  const { i18n } = useTranslation()

  return v1.getProductsInExplore.useInfiniteQuery(
    [{ identifier, search, per_page: ITEMS_PER_PAGE, locale: i18n.language }],
    { getNextPageParam: getNextPageParamV1 }
  )
}
