import { v1 } from "backoffice-api"
import type { TFunction } from "i18next"
import { useTranslation } from "react-i18next"
import { TEXT_FALLBACK } from "ui-tools"
import { QueryBoundary } from "utility-components"
import { exists, getEntries } from "utils"
import { useGetOrdinal } from "../../dataUtilities/getOrdinal"
import { usePickText } from "../../i18n/usePickText"
import { RecentActivityWidget } from "./RecentActivityWidget"

type ActivityFeed = v1["getActivityFeed"]
type ActivityItem = ReturnType<typeof useData>[number]

export const RecentActivityWidgetLoader = () => (
  <QueryBoundary fallback={<RecentActivityWidget.Skeleton />}>
    <Load />
  </QueryBoundary>
)

const Load = () => {
  const { t } = useTranslation()
  const getSummaryText = useGetSummaryText()
  const pickText = usePickText()

  const activityItems = useData()

  if (activityItems.length === 0) {
    return (
      <RecentActivityWidget title={t("activityFeed.activity")}>
        {t("activityFeed.no_activities")}
      </RecentActivityWidget>
    )
  }

  const cards = activityItems.map((item, index) => (
    <RecentActivityWidget.Entry
      key={index}
      type={item.type}
      image={item.image}
      name={item.first_name ?? TEXT_FALLBACK}
      productTitle={pickText(item.product)}
      timestamp={displayTimestamp(item.timestamp, t)}
      summaryText={getSummaryText(item)}
    />
  ))

  return (
    <RecentActivityWidget title={t("activityFeed.activity")}>
      {cards}
    </RecentActivityWidget>
  )
}

const useData = () => {
  return v1.getActivityFeed.useQuery({
    select: selectActivityFeed,
  })
}

const selectActivityFeed = (feed: ActivityFeed) => {
  return getEntries(feed)
    .map(([type, item]) => ("user_id" in item ? { ...item, type } : undefined))
    .filter(exists)
}

const displayTimestamp = (timestamp: string, t: TFunction) => {
  const time = new Date(timestamp)
  const now = new Date()
  const difference = now.getTime() - time.getTime()

  const ONE_HOUR = 1000 * 60 * 60
  const ONE_DAY = ONE_HOUR * 24
  const THREE_DAYS = ONE_DAY * 3

  const hours = Math.floor(difference / ONE_HOUR)
  const days = Math.floor(difference / ONE_DAY)

  if (difference < ONE_HOUR) return t("date.JUST_NOW")
  if (difference < ONE_DAY) return hours + t("date.HOUR_SHORT")
  if (difference < THREE_DAYS) return days + t("date.DAY_SHORT")
  return t("date.A_WHILE_AGO")
}

const useGetSummaryText = () => {
  const { t } = useTranslation()
  const getOrdinal = useGetOrdinal()

  return (item: ActivityItem) => {
    const { type, new_rank, new_stars } = item
    const rank = Number(new_rank)
    const stars = Number(new_stars)

    if (type === "stars") return t("activityFeed.stars", { count: stars })
    if (type === "leaderboard") return getOrdinal(rank)
    return t("activityFeed.completed")
  }
}
