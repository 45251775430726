import newsCardIllustration from "assets/illustrations/newsCardIllustration.svg"
import type { PropsWithChildren } from "react"
import { NewsCard } from "../NewsCard/NewsCard"
import s from "./NewsView.module.scss"

export const NewsView = ({ children }: PropsWithChildren) => (
  <div className={s.view}>
    <div className={s.view__posts}>{children}</div>
  </div>
)

type EmptyProps = {
  description: string
}
const Empty = ({ description }: EmptyProps) => (
  <div className={s.empty}>
    <img src={newsCardIllustration} className={s.empty__illustration} alt="" />
    <div className={s.empty__text}>{description}</div>
  </div>
)

const Skeleton = () => (
  <div className={s.skeleton}>
    <div className={s.skeleton__posts}>
      <NewsCard.Skeleton />
      <NewsCard.Skeleton />
      <NewsCard.Skeleton />
    </div>
  </div>
)

NewsView.Empty = Empty
NewsView.Skeleton = Skeleton
