import attensiLogoBlue from "assets/logos/attensiLogoBlue.svg"
import { useWindowWidth } from "materia"
import type { PropsWithChildren, ReactNode } from "react"
import { useTranslation } from "react-i18next"
import {
  backgroundImageStyle,
  optimizeImage,
  useOptimizeContext,
} from "ui-tools"
import { FiestaLink, type FiestaLinkProps } from "utility-components"
import { AppLauncherButton } from "../AppLauncherButton/AppLauncherButton"
import s from "./AppShell.module.scss"

type AppShellProps = {
  children: ReactNode
  backgroundImage: string | undefined | null
}
export const AppShell = ({ backgroundImage, children }: AppShellProps) => (
  <div className={s.app} style={backgroundImageStyle(backgroundImage)}>
    {children}
  </div>
)

type HeaderProps = {
  navigation: ReactNode
  menu: ReactNode
  companyLogo: string
  avatarLink: FiestaLinkProps
  avatar: string
  userDisplayName: string
}
const Header = ({
  navigation,
  menu,
  companyLogo,
  avatarLink,
  avatar,
  userDisplayName,
}: HeaderProps) => {
  const { isBigWindow } = useWindowWidth()
  const { t } = useTranslation()
  return (
    <header className={s.header}>
      <a href="#main" className={s.header__skipToMainContent}>
        {t("actions.SKIP_TO_MAIN_CONTENT")}
      </a>
      <div className={s.header__appLauncherButton}>
        <AppLauncherButton />
      </div>
      <div className={s.header__container}>
        <Logo src={companyLogo} />
        {!isBigWindow && (
          <FiestaLink className={s.header__avatarWrapper} {...avatarLink}>
            <img className={s.header__avatar} src={avatar} alt="Company logo" />
            <div className={s.header__userDisplayName}>{userDisplayName}</div>
          </FiestaLink>
        )}
        {isBigWindow && (
          <div className={s.header__navigation}>{navigation}</div>
        )}
        <div className={s.header__menu}>{menu}</div>
      </div>
    </header>
  )
}

const Logo = ({ src }: { src: string }) => {
  const context = useOptimizeContext()

  const optimizeLogo = (targetHeight: number) => {
    const { app, optimizeUrl } = context || {}
    if (!app || !optimizeUrl) return src

    const height = app.heights.find((height) => height >= targetHeight)
    return optimizeImage({ app, optimizeUrl, src, height })
  }

  return (
    <FiestaLink to="/">
      <img
        className={s.header__logo}
        src={optimizeLogo(50)}
        alt="Company logo"
      />
    </FiestaLink>
  )
}

const HeaderSkeleton = () => <div className={s.headerSkeleton} />

const Main = ({ children }: PropsWithChildren) => (
  <main id="main" className={s.main}>
    {children}
  </main>
)

const Footer = () => (
  <footer className={s.footer}>
    <img className={s.footer__logo} src={attensiLogoBlue} alt="Attensi logo" />
  </footer>
)

const BottomBar = ({ children }: PropsWithChildren) => (
  <div className={s.bottomBar}>{children}</div>
)

const BottomNavigation = ({ children }: PropsWithChildren) => {
  const { isBigWindow } = useWindowWidth()
  if (isBigWindow) return null

  return <div className={s.bottomNavigationBar}>{children}</div>
}

AppShell.Header = Header
AppShell.HeaderSkeleton = HeaderSkeleton
AppShell.Main = Main
AppShell.Footer = Footer
AppShell.BottomBar = BottomBar
AppShell.BottomNavigation = BottomNavigation
