import arrow from "assets/icons/arrowRight.svg"
import { v3 } from "backoffice-api"
import { useTranslation } from "react-i18next"
import { QueryBoundary } from "utility-components"
import { usePickText } from "../../i18n/usePickText"
import { NewsWidgetMobile } from "./NewsWidgetMobile"

export const NewsWidgetMobileLoader = () => {
  return (
    <QueryBoundary fallback={<NewsWidgetMobile.Skeleton />}>
      <NewsWidgetMobile>
        <Load />
      </NewsWidgetMobile>
    </QueryBoundary>
  )
}

const Load = () => {
  const { data } = v3.getNewsPosts.useQuery([{ "page[size]": 1 }])
  const pickText = usePickText()
  const { t } = useTranslation()

  if (data.length === 0) {
    return <NewsWidgetMobile.Empty emptyText={t("notFound.NOTFOUND_NEWS")} />
  }

  const items = data.map((item) => {
    return (
      <NewsWidgetMobile.Item
        link={{ to: `/news/post/${item.id}` }}
        key={item.id}
        title={t("news.NEWS")}
        description={pickText(item.headline)}
        image={item.image?.file_url ?? ""}
        arrow={arrow}
      />
    )
  })

  return items
}
