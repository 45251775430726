import { auth } from "auth"
import { v3 } from "backoffice-api"
import { withQueryParams } from "utils"
import { DEVICE_PLATFORM } from "../constants"
import { APP_ENV } from "../env"
import { APP_TYPE } from "../vuplex/constants"

type Product = v3["getProduct"]["data"]

export const getStartAppLink = async (product: Product) => {
  const { platform } = DEVICE_PLATFORM
  return addParams(product.content_link, product, platform)
}

export const getLauncherLink = async (product: Product) => {
  const { platform } = DEVICE_PLATFORM
  return addParams(product.launcher_link, product, platform)
}

export const getWebGLLink = async (product: Product) => {
  return addParams(product.webgl_content_link, product, "WebGL")
}

const addParams = async (
  link: string,
  product: Product,
  platform: string | undefined
) => {
  if (!platform) return

  const [{ api_token, company }, { accessToken, transfer_key }] =
    await Promise.all([
      getProductParams(product.id, platform),
      getAccessParams(product),
    ])

  if (api_token === undefined) return

  const params = {
    env: APP_ENV,
    api_token,
    company,
    accessToken,
    transfer_key,
    product: product.token,
    portalRedirectPath: getPortalRedirectPath(product),
  }

  return withQueryParams(link, params)
}

const getAccessParams = async ({ use_transfer_token }: Product) => {
  if (APP_TYPE === "skills") return {}

  const { accessToken } = await auth.getActiveSession()

  if (use_transfer_token) {
    return { transfer_key: await auth.accessTokenToTransferKey(accessToken) }
  } else {
    return { accessToken }
  }
}

const getPortalRedirectPath = (product: Product) => {
  if (product.product_type.identifier === "skill") {
    return window.location.pathname
  }
}

export const preloadPlayLinksData = (product_id: string) => {
  return v3.getProduct.fetchQueryOnce([
    product_id,
    { include: ["oauth_applications", "company"] },
  ])
}

const getProductParams = async (product_id: string, platform: string) => {
  const { data } = await preloadPlayLinksData(product_id)

  const app = data.oauth_applications?.find((app) =>
    isPlatformEqual(app.platform, platform)
  )
  const api_token = app?.api_token

  const company = data.company?.name_key

  if (!company) {
    throw new Error("Product is missing company")
  }

  return { api_token, company }
}

const isPlatformEqual = (left: string, right: string) => {
  return left.toLowerCase().trim() === right.toLowerCase().trim()
}
