import { initAppLauncher } from "@attensi/app-launcher"

import { auth } from "auth"
import * as env from "../env"

export const initializeAppLauncher = async () => {
  const { accessToken, subdomain, companyNameKey } =
    await auth.getActiveSession()

  initAppLauncher({
    env: env.APP_ENV,
    apiUrl: env.BACKOFFICE_API_URL,
    authUrl: env.AUTH_URL,
    apiToken: env.API_TOKEN,
    accessToken,
    subdomain,
    companyNameKey,
  })
}
