export const TEXT_FALLBACK = "N/A"

export const FULLSCREEN_LAYOUT_PARAMETERS = {
  layout: "fullscreen",
}

export const DARK_BACKGROUND_PARAMETERS = {
  backgrounds: { default: "steel" },
}

export const PADDED_LAYOUT_PARAMETERS = {
  layout: "padded",
}

export const TABLET_VIEWPORT_PARAMETERS = {
  chromatic: { viewports: [834] },
  viewport: {
    defaultViewport: "tablet",
  },
}

export const MOBILE_VIEWPORT_PARAMETERS = {
  chromatic: { viewports: [414] },
  viewport: {
    defaultViewport: "mobile2",
  },
}

export const SMALL_MOBILE_VIEWPORT_PARAMETERS = {
  chromatic: { viewports: [320] },
  viewport: {
    defaultViewport: "mobile1",
  },
}

export const LOREM_IPSUM =
  "Lorem ipsum dolor sit amet consectetur adipisicing elit. Assumenda repellat facilis, maxime, reiciendis blanditiis voluptate odit, ex tempora deserunt dicta fugit eveniet omnis vel voluptates temporibus labore quod! Officiis, iste!"

export const DISABLE_CHROMATIC_PARAMETERS = {
  chromatic: { disableSnapshot: true },
}
