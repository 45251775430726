import { Loader } from "materia"
import type { ReactNode } from "react"
import { QueryBoundary } from "utility-components"
import { custom } from "../../bonzai/bonzai"
import { KpiSalesWidgetLoader } from "../KpiSalesWidget/KpiSalesWidgetLoader"
import { LeaderboardWidgetLoader } from "../LeaderboardWidget/LeaderboardWidgetLoader"
import { NewsWidgetLoader } from "../NewsWidget/NewsWidgetLoader"
import { RecentActivityWidgetLoader } from "../RecentActivityWidget/RecentActivityWidgetLoader"
import { SubHeadersLoader } from "../SubHeadersLoader"
import { UserWidgetLoader } from "../UserWidget/UserWidgetLoader"
import { WidgetLayout } from "./WidgetLayout"

type WidgetLayoutLoaderProps = {
  children: ReactNode
  showUserInfoHeader?: boolean
}
export const WidgetLayoutLoader = ({
  children,
  showUserInfoHeader,
}: WidgetLayoutLoaderProps) => (
  <>
    <SubHeadersLoader showUserInfoHeader={showUserInfoHeader} />
    <WidgetLayout widgets={<WidgetsLoader />}>
      <QueryBoundary fallback={<Loader />}>{children}</QueryBoundary>
    </WidgetLayout>
  </>
)

const WidgetsLoader = () => {
  const flags = custom.getPortalConfigurations.useQuery({ suspense: false })

  if (!flags) return <WidgetLayout.WidgetsSkeleton />

  return (
    <>
      {<UserWidgetLoader />}
      {flags.enable_portal_news && <NewsWidgetLoader />}
      {!flags.disable_leaderboard && <LeaderboardWidgetLoader />}
      {flags.enable_kpi_sales_tiles && <KpiSalesWidgetLoader />}
      {flags.enable_activity_feed && <RecentActivityWidgetLoader />}
    </>
  )
}
