import { proxy } from "valtio"

type VuplexStore = {
  productId: number | undefined
  pushNotificationsEnabled: boolean | undefined
}

export const vuplexStore = proxy<VuplexStore>({
  productId: undefined,
  pushNotificationsEnabled: undefined,
})
