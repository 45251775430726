import checkmarkCircleGreen from "assets/icons/checkmarkCircleGreen.svg"
import dueDateClock from "assets/icons/dueDateClock.svg"
import dueDateOverdue from "assets/icons/dueDateOverdue.svg"
import starEmpty from "assets/icons/starEmpty.svg"
import starFilled from "assets/icons/starFilled.svg"
import { times } from "lodash-es"
import { useTranslation } from "react-i18next"
import { modifiers } from "ui-tools"
import { FiestaLink, type FiestaLinkProps } from "utility-components"
import { ProductCard } from "../ProductCard/ProductCard"
import s from "./ProductCardEvent.module.scss"

type Props = {
  link: FiestaLinkProps
  info: string
  title: string
  month: string
  day: string
  stars: number
  starsMax: number

  isCertified: boolean
  isMandatory: boolean
  isAttending: boolean
  isOnWaitingList: boolean
  isFullyBooked: boolean
  isEmpty: boolean
  isOverdue: boolean
  isDue: boolean

  mandatoryText: string
}

export const ProductCardEvent = (props: Props) => {
  const { link, isMandatory } = props

  return (
    <ProductCard.Shell>
      <FiestaLink className={s.card} {...link}>
        <CertifiedOrDeadline {...props} />

        {isMandatory && <Mandatory {...props} />}
        <Info {...props} />
        <Date {...props} />
        <Title {...props} />
        <Stars {...props} />
      </FiestaLink>
    </ProductCard.Shell>
  )
}

const CertifiedOrDeadline = ({ isDue, isOverdue, isCertified }: Props) => {
  if (isDue) return <DueDate />
  if (isOverdue) return <Overdue />
  if (isCertified) return <Certified />
}

const DueDate = () => {
  const { t } = useTranslation()
  return (
    <div className={modifiers(s, "deadline")}>
      <img className={s.deadline__icon} src={dueDateClock} alt="" />
      {t("product.DUE_DATE")}
    </div>
  )
}

const Overdue = () => {
  const { t } = useTranslation()
  return (
    <div className={modifiers(s, "deadline", "overdue")}>
      <img className={s.deadline__icon} src={dueDateOverdue} alt="" />
      {t("product.OVERDUE")}
    </div>
  )
}

const Certified = () => (
  <img className={s.certified} src={checkmarkCircleGreen} alt="" />
)

const Mandatory = ({ mandatoryText }: Props) => (
  <div className={s.mandatory}>{mandatoryText}</div>
)

const Info = ({
  info,
  isCertified,
  isAttending,
  isFullyBooked,
  isEmpty,
  isOnWaitingList,
}: Props) => (
  <div
    className={modifiers(s, "info", {
      isCertified,
      isAttending,
      isFullyBooked,
      isEmpty,
      isOnWaitingList,
    })}
  >
    {info}
  </div>
)

const Date = ({ isEmpty, month, day }: Props) => {
  if (isEmpty) return null

  return (
    <div className={s.date}>
      <div className={s.date__month}>{month}</div>
      <div className={s.date__day}>{day}</div>
    </div>
  )
}

const Title = ({ title }: Props) => <h3 className={s.title}>{title}</h3>

type StarsProps = {
  stars: number
  starsMax: number
}
const Stars = ({ starsMax, stars }: StarsProps) => {
  const getSrc = (isFilled: boolean) => (isFilled ? starFilled : starEmpty)

  const starElements = times(starsMax, (index) => (
    <img
      className={s.stars__image}
      src={getSrc(index < stars)}
      alt=""
      key={index}
    />
  ))

  return <div className={s.stars}>{starElements}</div>
}
