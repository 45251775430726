import { useModal, useRouteParamNumber } from "hooks"
import { QueryBoundary } from "utility-components"
import { custom } from "../../bonzai/bonzai"
import { useSubscribeToVuplex } from "../../vuplex/subscribeToVuplex"
import { useSyncBannerProductId } from "../CompletedProductBanner/CompletedProductBannerLoader"
import { ProductModal } from "../ProductModal/ProductModal"
import { ProductModalLoader } from "../ProductModal/ProductModalLoader"
import { ProductModalActivity } from "../ProductModalActivity/ProductModalActivity"
import { ProductModalActivityLoader } from "../ProductModalActivity/ProductModalActivityLoader"
import { ProductModalEventLoader } from "../ProductModalEvent/ProductModalEventLoader"
import { ProductModalLeaderboardLoader } from "../ProductModalLeaderboard/ProductModalLeaderboardLoader"
import { ProductModalShell } from "./ProductModalShell"

type ProductModalShellLoaderProps = {
  closeRoute: string
  sideContent?: "leaderboard" | "activity" | "event"
}
export const ProductModalShellLoader = ({
  closeRoute,
  sideContent,
}: ProductModalShellLoaderProps) => {
  const productId = useRouteParamNumber("productId")
  const { disable_leaderboard } = custom.getPortalConfigurations.useQuery()

  const { onClose } = useModal(closeRoute)

  useSyncBannerProductId(productId)

  useSubscribeToVuplex("CLOSE_PRODUCT_MODALS", onClose)

  return (
    <ProductModalShell>
      <ProductModalShell.Content onClose={onClose}>
        <ProductModalShell.CloseButton onClick={onClose} />
        <QueryBoundary
          fallback={<Skeleton showSideContent={sideContent !== undefined} />}
        >
          <ProductModalLoader productId={productId} />

          {sideContent === "leaderboard" && !disable_leaderboard && (
            <ProductModalLeaderboardLoader productId={productId} />
          )}

          {sideContent === "activity" && (
            <ProductModalActivityLoader
              productId={productId}
              onClose={onClose}
            />
          )}

          {sideContent === "event" && (
            <ProductModalEventLoader productId={productId} />
          )}
        </QueryBoundary>
      </ProductModalShell.Content>
    </ProductModalShell>
  )
}

type SkeletonProps = {
  showSideContent: boolean
}
const Skeleton = ({ showSideContent }: SkeletonProps) => (
  <>
    <ProductModal.Skeleton />
    {showSideContent && <ProductModalActivity.Skeleton />}
  </>
)
