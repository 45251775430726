import { v3 } from "backoffice-api"
import { useQueryAll } from "bonzai"
import { useRouteParamNumber, useScrollToTopOnMount } from "hooks"
import { useTranslation } from "react-i18next"
import { QueryBoundary } from "utility-components"
import { usePickText } from "../../i18n/usePickText"
import { ProductCardLoader } from "../ProductCard/ProductCardLoader"
import { ProductList } from "../ProductList/ProductList"
import { ProductBundleView } from "./ProductBundleView"

export const ProductBundleViewLoaderHeader = () => {
  return (
    <QueryBoundary fallback={<ProductBundleView.HeaderSkeleton />}>
      <LoadHeader />
    </QueryBoundary>
  )
}

const LoadHeader = () => {
  const pickText = usePickText()
  const bundleId = useRouteParamNumber("bundleId").toString()
  const [bundle, progress] = useHeaderData(bundleId)

  return (
    <>
      <ProductBundleView.Header
        progress={progress.certified_count}
        progressMax={progress.certified_max}
        stars={progress.stars_count}
        starsMax={progress.stars_max}
        title={pickText(bundle.title)}
        backLink={{ to: "/for-you" }}
      />
    </>
  )
}

export const ProductBundleViewLoader = () => {
  useScrollToTopOnMount()
  const { t } = useTranslation()

  return (
    <ProductBundleView>
      <QueryBoundary fallback={<ProductList.Skeleton />}>
        <Load />
      </QueryBoundary>
      <ProductBundleView.Back
        text={t("actions.BACK")}
        link={{ to: "/for-you" }}
      />
    </ProductBundleView>
  )
}

const Load = () => {
  const pickText = usePickText()

  const bundleId = useRouteParamNumber("bundleId").toString()
  const bundleProducts = useBundleProducts(bundleId)

  const productCards = bundleProducts.data.map((product) => (
    <ProductCardLoader
      key={product.id}
      productId={Number(product.id)}
      productType={product.product_type.identifier}
      productImage={product.product_image_url}
      productTitle={pickText(product.title)}
      isScorable={!product.no_score}
      apiVersion="v3"
    />
  ))

  return <ProductList>{productCards}</ProductList>
}

const useHeaderData = (bundleId: string) => {
  return useQueryAll(() => [
    v3.getBundle.useQuery([bundleId], {
      select: (res) => res.data,
    }),

    v3.getBundleProgress.useQuery([bundleId], {
      select: (res) => res.data,
    }),
  ])
}

const useBundleProducts = (bundleId: string) => {
  return v3.getBundleProducts.useQuery([
    { bundleId: bundleId, sort: "position" },
  ])
}
