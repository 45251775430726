import closeIcon from "assets/icons/closeIcon.svg"
import { useOnClickedOutside } from "hooks"
import { Dialog, useWindowWidth } from "materia"
import {
  useContext,
  useMemo,
  useRef,
  useState,
  type PropsWithChildren,
  type ReactNode,
} from "react"
import { createPortal } from "react-dom"
import { ActionButtonsContext } from "./ActionButtonsContext"
import s from "./ProductModalShell.module.scss"

export const ProductModalShell = ({ children }: { children: ReactNode }) => (
  <Dialog className={s.modal} aria-label="Play product">
    {children}
  </Dialog>
)

const Content = ({
  children,
  onClose,
}: {
  children: ReactNode
  onClose: () => void
}) => {
  const ref = useRef<HTMLDivElement>(null)
  useOnClickedOutside(ref, onClose)
  const { isBigWindow } = useWindowWidth()

  const [refState, setRefState] = useState<HTMLDivElement | null>(null)
  const contextValue = useMemo(() => ({ targetNode: refState }), [refState])

  return (
    <ActionButtonsContext.Provider value={contextValue}>
      <div className={s.content} ref={ref}>
        <div className={s.content__scrollContainer}>
          {children}
          {!isBigWindow && <div ref={setRefState} />}
        </div>
      </div>
    </ActionButtonsContext.Provider>
  )
}

const Main = ({ children }: PropsWithChildren) => (
  <div className={s.main}>{children}</div>
)

const Side = ({ children }: PropsWithChildren) => (
  <div className={s.side}>{children}</div>
)

const ActionButtons = ({ children }: PropsWithChildren) => {
  const contextValue = useContext(ActionButtonsContext)
  const { isBigWindow } = useWindowWidth()

  if (!contextValue || isBigWindow) {
    return <>{children}</>
  }

  const { targetNode } = contextValue
  if (!targetNode) return null

  const element = (
    <div className={s.actionButtons}>
      <div className={s.actionButtons__content}>{children}</div>
    </div>
  )

  return createPortal(element, targetNode)
}

type CloseButtonProps = {
  onClick: () => void
}
const CloseButton = ({ onClick }: CloseButtonProps) => (
  <button className={s.closeButton} onClick={onClick}>
    <img className={s.closeButton__icon} src={closeIcon} alt="Close" />
  </button>
)

ProductModalShell.Content = Content
ProductModalShell.Main = Main
ProductModalShell.Side = Side
ProductModalShell.ActionButtons = ActionButtons
ProductModalShell.CloseButton = CloseButton
