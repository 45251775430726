import { v1, v3 } from "backoffice-api"
import { useHasValueChanged, useToggle } from "hooks"

import { useQueryAll } from "bonzai"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { QueryBoundary } from "utility-components"
import { usePickText } from "../../i18n/usePickText"
import { JourneyCardLoader } from "../JourneyCard/JourneyCardLoader"
import { JourneyUnlockedModal } from "../JourneyUnlockedModal/JourneyUnlockedModal"
import { Journey } from "./Journey"

type Category = v1["getProductCategories"]["data"][number]

type JourneyLoaderProps = {
  journey: Category
  isUnlockedJourney: boolean
  setUnlockedjourneyId: (unlockedJourneyId: string) => void
}
export const JourneyLoader = (props: JourneyLoaderProps) => (
  <QueryBoundary fallback={<Journey.Skeleton isOpen />}>
    <Load {...props} />
  </QueryBoundary>
)

const Load = ({
  journey,
  isUnlockedJourney,
  setUnlockedjourneyId,
}: JourneyLoaderProps) => {
  const [products, progress] = useData(journey.id)

  const { t } = useTranslation()
  const pickText = usePickText()

  const { certified_count, certified_max } = progress
  const isIncomplete = certified_count < certified_max

  const [isUnlockedModalOpen, toggleIsUnlockedModalOpen] = useToggle(true)

  const description = pickText(journey.description, "")
  const descriptionImage = journey.image_url
  const [isInitiallyLocked] = useState(progress.locked)
  const isLocked = isUnlockedJourney ? false : isInitiallyLocked
  const firstOnlockedCategory = progress.product_categories_unlocked?.[0]
  const hasUnlockedNewJourney = useHasValueChanged(firstOnlockedCategory?.id)

  const [isOpen, toggleIsOpen] = useToggle(isIncomplete)
  const [isDescriptionOpen, toggleIsDescriptionOpen] = useToggle(isLocked)

  const lockedByJourneyTitle = progress.product_categories
    ?.map((category) => pickText(category.title))
    .join(", ")

  const journeyCards = products.map((product) => (
    <JourneyCardLoader key={product.id} product={product} isLocked={isLocked} />
  ))

  const continueFromUnlockedJourney = () => {
    if (firstOnlockedCategory) {
      setUnlockedjourneyId(firstOnlockedCategory.id)
    }
    toggleIsUnlockedModalOpen()
  }

  return (
    <>
      <Journey
        backgroundImage={journey.background_image_url ?? ""}
        isLocked={isLocked}
        isDescriptionOpen={isDescriptionOpen}
        onClick={toggleIsDescriptionOpen}
      >
        <Journey.Header
          title={pickText(journey.titles)}
          isOpen={isOpen}
          isLocked={isLocked}
          isCertified={certified_count === certified_max}
          progress={certified_count}
          progressMax={certified_max}
          lockedByTitle={lockedByJourneyTitle}
          onClick={toggleIsOpen}
        />
        <Journey.Content isOpen={isOpen}>
          <Journey.JourneyDescriptionIcon
            isDescriptionOpen={isDescriptionOpen}
            onClick={toggleIsDescriptionOpen}
            journeyDescriptionText={description}
            isLocked={isLocked}
          />

          <Journey.JourneyDescription
            journeyDescriptionImage={descriptionImage ?? undefined}
            journeyDescriptionText={description}
            isDescriptionOpen={isDescriptionOpen}
            isLocked={isLocked}
            firstLockedByJourneyText={t("journey.LOCKED_BY_JOURNEY_TEXT_FIRST")}
            lockedByJourneyTitle={lockedByJourneyTitle}
            lastLockedByJourneyText={t("journey.LOCKED_BY_JOURNEY_TEXT_LAST")}
          />
          <Journey.Products>{journeyCards}</Journey.Products>
        </Journey.Content>
      </Journey>

      {hasUnlockedNewJourney &&
        isUnlockedModalOpen &&
        firstOnlockedCategory && (
          <JourneyUnlockedModal
            buttonTitle={t("journey.NEW_JOURNEY_UNLOCKED_CONTINUE")}
            journeyImage={firstOnlockedCategory.background_image_url ?? ""}
            journeyTitle={pickText(firstOnlockedCategory.title)}
            modalTitle={t("journey.NEW_JOURNEY_UNLOCKED")}
            onClose={toggleIsUnlockedModalOpen}
            onContinue={continueFromUnlockedJourney}
          />
        )}
    </>
  )
}

const useData = (journeyId: number) => {
  const { i18n } = useTranslation()

  return useQueryAll(() => [
    v1.getProductsInCategory.useQuery(
      [{ category_id: journeyId, locale: i18n.language, per_page: 20 }],
      { select: (res) => res.data }
    ),

    v3.getProductCategoryProgress.useQuery(
      [
        journeyId,
        { include: ["product_categories", "product_categories_unlocked"] },
      ],
      {
        select: (res) => res.data,
      }
    ),
  ])
}
