import addToCalendarIcon from "assets/icons/addToCalendar.svg"
import { ReactComponent as ArrowDownIcon } from "assets/icons/arrowDown.svg"
import pinIcon from "assets/icons/pin.svg"
import waitingList from "assets/icons/waitingList.svg"
import scaredBallonPerson from "assets/illustrations/scaredBallonPerson.svg"
import { useWindowWidth } from "materia"
import type { PropsWithChildren, ReactNode } from "react"
import { modifiers } from "ui-tools"
import { ProductModalShell } from "../ProductModalShell/ProductModalShell"
import s from "./ProductModalEvent.module.scss"

export const ProductModalEvent = ({ children }: PropsWithChildren) => (
  <ProductModalShell.Side>
    <div className={s.modal}>{children}</div>
  </ProductModalShell.Side>
)

type HeaderProps = {
  text: string
  color?: "red"
}
const Header = ({ text, color }: HeaderProps) => (
  <h3 className={modifiers(s, "header", color)}>{text}</h3>
)

type TicketProps = {
  isWaiting: boolean
  attendingText: string
  date: string
  time: string
}
const Ticket = ({ isWaiting, attendingText, date, time }: TicketProps) => (
  <div className={modifiers(s, "ticket", { isWaiting })}>
    <div className={s.ticket__attending}>{attendingText}</div>
    <div className={s.ticket__date}>{date}</div>
    <div className={s.ticket__time}>{time}</div>
  </div>
)

const DateButtons = ({ children }: PropsWithChildren) => (
  <div className={s.dateButtons}>{children}</div>
)

type DateButtonProps = {
  text: string
  color: "blue" | "white"
  href: string
}
const DateButton = ({ color, text, href }: DateButtonProps) => (
  <a
    href={href}
    target="_blank"
    rel="noreferrer"
    className={modifiers(s, "dateButton", color)}
  >
    {text}
    <ArrowDownIcon />
  </a>
)

type AddToCalendarProps = {
  text: string
  onClick: () => void
}
const AddToCalendar = ({ text, onClick }: AddToCalendarProps) => (
  <button className={s.dateButtons__calendar} onClick={onClick}>
    <img src={addToCalendarIcon} alt="" />
    {text}
  </button>
)

type DatesProps = {
  isOverflow: boolean
  children: ReactNode
}
const Dates = ({ isOverflow, children }: DatesProps) => (
  <div className={modifiers(s, "dates", { isOverflow })}>{children}</div>
)

type DateProps = {
  month: string
  day: string
  time: string
  isActive: boolean
  isCancelled: boolean
  onClick: () => void
}
const Date = ({
  isActive,
  onClick,
  month,
  day,
  time,
  isCancelled,
}: DateProps) => {
  return (
    <button
      className={modifiers(s, "date", { isActive, isCancelled })}
      onClick={onClick}
    >
      <div className={s.date__month}>{month}</div>
      <div className={s.date__day}>{day}</div>
      <div className={s.date__time}>{time}</div>
    </button>
  )
}

type toggleShowMoreProps = {
  buttonText: string
  onClick: () => void
}
const ToggleShowMore = ({ buttonText, onClick }: toggleShowMoreProps) => {
  const { isBigWindow } = useWindowWidth()
  if (!isBigWindow) return null

  return (
    <button className={s.toggleShowMore} onClick={onClick}>
      {buttonText}
    </button>
  )
}

type WaitingInfoProps = {
  text: string
}
const WaitingInfo = ({ text }: WaitingInfoProps) => (
  <div className={s.waitingInfo}>
    <div className={s.waitingInfo__text}>{text}</div>
    <img className={s.waitingInfo__icon} src={waitingList} alt="" />
  </div>
)

type InfoListProps = {
  children: ReactNode
  isOpaque?: boolean
  address: string
  isUrl: boolean
}
const InfoList = ({ isOpaque, isUrl, children, address }: InfoListProps) => (
  <div className={modifiers(s, "infoList", { isOpaque })}>
    <div className={s.infoList__address}>
      <img className={s.infoList__addressIcon} src={pinIcon} alt="" />
      <div className={s.infoList__addressValue}>
        {isUrl ? (
          <a
            className={s.infoList__url}
            href={address}
            target="_blank"
            rel="noreferrer"
          >
            {address}
          </a>
        ) : (
          address
        )}
      </div>
    </div>
    <div className={s.infoList__wrapper}>{children}</div>
  </div>
)

type InfoProps = {
  icon: string
  label: string
  color?: "red"
  value: string
}
const Info = ({ icon, value, label, color }: InfoProps) => (
  <div className={modifiers(s, "info", color)}>
    <img className={s.info__icon} src={icon} alt="" />
    <div className={s.info__label}>{label}</div>
    <div className={s.info__value}>{value}</div>
  </div>
)

const Buttons = ({ children }: PropsWithChildren) => (
  <ProductModalShell.ActionButtons>
    <div className={s.buttons}>{children}</div>
  </ProductModalShell.ActionButtons>
)

type ButtonProps = {
  variant: "attend" | "unregister" | "waiting-list" | "disabled"
  onClick: () => void
  children: ReactNode
}
const Button = ({ variant, children, onClick }: ButtonProps) => (
  <button
    className={modifiers(s, "button", variant)}
    onClick={onClick}
    disabled={variant === "disabled"}
  >
    {children}
  </button>
)

type EmptyProps = {
  header: string
}
const Empty = ({ header }: EmptyProps) => (
  <div className={s.empty}>
    <h3 className={s.empty__header}>{header}</h3>
    <img className={s.empty__image} src={scaredBallonPerson} alt="" />
  </div>
)

ProductModalEvent.Header = Header
ProductModalEvent.Ticket = Ticket
ProductModalEvent.DateButtons = DateButtons
ProductModalEvent.DateButton = DateButton
ProductModalEvent.Dates = Dates
ProductModalEvent.AddToCalendar = AddToCalendar
ProductModalEvent.Date = Date
ProductModalEvent.ToggleShowMore = ToggleShowMore
ProductModalEvent.WaitingInfo = WaitingInfo
ProductModalEvent.InfoList = InfoList
ProductModalEvent.Info = Info
ProductModalEvent.Buttons = Buttons
ProductModalEvent.Button = Button
ProductModalEvent.Empty = Empty
