import { accessTokenToTransferKey } from "../api/transferKey"
import { getActiveSession } from "../getActiveSession"
import { redirectTo } from "./redirectTo"

type Options = {
  url: string
}

export const redirectWithActiveSession = async ({ url }: Options) => {
  const { subdomain, accessToken, companyNameKey } = await getActiveSession()
  const transfer_key = await accessTokenToTransferKey(accessToken)
  return redirectTo(url, {
    subdomain,
    transfer_key,
    company_name_key: companyNameKey,
  })
}
