import type { OptimizerApp } from "optimizer-core"
import { createContext, useContext } from "react"

export type OptimizeContextType = {
  app: OptimizerApp | undefined
  optimizeUrl: string | undefined
}

const OptimizeContext = createContext<OptimizeContextType | null>(null)

export const OptimizeContextProvider = OptimizeContext.Provider

export const useOptimizeContext = () => {
  return useContext(OptimizeContext)
}
