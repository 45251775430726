import type { Session } from "../types/Session"

type StorageType = "sessionStorage" | "localStorage"

const SESSIONS_KEY = "stored_sessions_v3"
const LEGACY_KEYS = ["stored_sessions_v2", "stored_sessions_v1", "sessions_v1"]

export const addSessionToStorage = async (session: Session): Promise<void> => {
  await removeSessionFromStorage(session)

  const storageType = getStorageType(session)
  const sessions = await getSessionsFromStorage(storageType)
  saveToStorage(storageType, [session, ...sessions])
}

export const removeSessionFromStorage = async (
  session: Session
): Promise<Session[]> => {
  const storageType = getStorageType(session)

  const sessions = await getSessionsFromStorage(storageType)
  const removedSessions = sessions.filter(isSessionFilter(session))
  const updatedSessions = sessions.filter(isNotSessionFilter(session))
  saveToStorage(storageType, updatedSessions)

  return removedSessions
}

export const getSessionsByEnvironment = async (
  environment: string
): Promise<Session[]> => {
  const result = await Promise.all([
    getSessionsFromStorage("sessionStorage"), // Check sessionStorage first
    getSessionsFromStorage("localStorage"),
  ])

  return result.flat().filter((session) => session.environment === environment)
}

const getSessionsFromStorage = async (storageType: StorageType) => {
  return getFromStorage<Session[]>(storageType, SESSIONS_KEY) ?? []
}

const getStorageType = (session: Session): StorageType => {
  if (session.rememberMe) return "localStorage"
  return "sessionStorage"
}

const isSessionFilter = (sessionA: Session) => (sessionB: Session) =>
  isEqual(sessionA, sessionB)

const isNotSessionFilter = (sessionA: Session) => (sessionB: Session) =>
  !isEqual(sessionA, sessionB)

const isEqual = (sessionA: Session, sessionB: Session) => {
  if (sessionA.subdomain !== sessionB.subdomain) {
    return false
  }
  if (sessionA.companyNameKey !== sessionB.companyNameKey) {
    return false
  }
  if (sessionA.environment !== sessionB.environment) {
    return false
  }
  return true
}

const saveToStorage = (storage: StorageType, value: unknown) => {
  const data = JSON.stringify(value)
  try {
    if (storage === "localStorage") {
      localStorage.setItem(SESSIONS_KEY, data)
    } else {
      sessionStorage.setItem(SESSIONS_KEY, data)
    }
  } catch (error) {
    console.error(error)
  }
}

const getFromStorage = <T>(
  storage: StorageType,
  key: string
): T | undefined => {
  let data
  try {
    if (storage === "localStorage") {
      data = localStorage.getItem(key)
    } else {
      data = sessionStorage.getItem(key)
    }
  } catch (error) {
    console.error(error)
  }

  if (!data) return
  return JSON.parse(data)
}

const removeLegacyData = async () => {
  for (const key of LEGACY_KEYS) {
    try {
      localStorage.removeItem(key)
    } catch (error) {
      console.error(error)
    }
  }
}

// Only run in production to avoid invalidating tokens from other apps in development
if (import.meta.env.PROD) {
  removeLegacyData()
}
