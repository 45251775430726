import type { AppConfig } from "../types/AppConfig"

export const APP_CONFIG: AppConfig = [
  {
    name: "portal",
    widths: [60, 100, 200, 300, 500, 600, 800, 900, 1200, 1500],
    heights: [50, 175],
  },
  {
    name: "admin",
    widths: [50, 100, 500, 800, 1000],
    heights: [],
  },
]
