import { v1 } from "backoffice-api"
import { useScrollToTopOnMount } from "hooks"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { QueryBoundary, WhenVisible } from "utility-components"
import { getNextPageParamV1 } from "../../bonzai/getNextPageParamV1"
import { HiddenHeader } from "../HiddenHeader"
import { JourneyLoader } from "../Journey/JourneyLoader"
import { JourneyView } from "./JourneyView"

type Category = v1["getProductCategories"]["data"][number]

export const JourneyViewLoader = () => {
  useScrollToTopOnMount()
  const { t } = useTranslation()

  return (
    <JourneyView>
      <HiddenHeader title={t("navigation.JOURNEY")} tag="h1" />
      <QueryBoundary fallback={<JourneyView.Skeleton />}>
        <Load />
      </QueryBoundary>
    </JourneyView>
  )
}

const Load = () => {
  const { t } = useTranslation()
  const journeysData = useData()
  const { data, fetchNextPage, isFetching } = journeysData

  const isEmpty = data.pages[0]?.length === 0

  if (isEmpty) {
    return <JourneyView.Empty description={t("notFound.NOTFOUND_JOURNEY")} />
  }

  const pages = data.pages.map((categories, index) => (
    <JourneysPage
      key={index}
      journeys={categories}
      loadMore={fetchNextPage}
      isLastPage={index === data.pages.length - 1}
    />
  ))

  return (
    <>
      {pages}
      {isFetching && <JourneyView.Skeleton />}
    </>
  )
}

type JourneysPageProps = {
  journeys: Category[]
  loadMore: () => void
  isLastPage: boolean
}
const JourneysPage = ({
  journeys,
  loadMore,
  isLastPage,
}: JourneysPageProps) => {
  const { t } = useTranslation()
  const [unlockedJourneyId, setUnlockedjourneyId] = useState<string>()

  const journeyElements = journeys.map((journey, index) => {
    return (
      <JourneyLoader
        journey={journey}
        isUnlockedJourney={String(journey.id) === unlockedJourneyId}
        setUnlockedjourneyId={setUnlockedjourneyId}
        key={index}
      />
    )
  })

  return (
    <>
      {journeyElements}
      {isLastPage && <WhenVisible whenVisible={loadMore} />}
    </>
  )
}

const useData = () => {
  const { i18n } = useTranslation()

  return v1.getProductCategories.useInfiniteQuery(
    [{ journey: true, per_page: 3, locale: i18n.language }],
    {
      getNextPageParam: getNextPageParamV1,
      select: (res) => res.data.filter((category) => !category.palp_only),
    }
  )
}
