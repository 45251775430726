import { useLayoutEffect, useRef, type DialogHTMLAttributes } from "react"

export const Dialog = (props: DialogHTMLAttributes<HTMLDialogElement>) => {
  const ref = useRef<HTMLDialogElement>(null)

  useLayoutEffect(() => {
    try {
      // showModal is necessary for the dialog element to behave like a modal.
      ref.current?.showModal()
    } catch (e) {
      // showModal will trow if called twice, and useLayoutEffect triggers twice in dev mode
      console.warn(e)
    }
  }, [])

  return <dialog ref={ref} {...props} />
}
