import { getQueryParams } from "utils"
import { setConfig } from "./config"
import type { RedirectStrategy } from "./types/RedirectStrategy"
import { removeQueryParams } from "./utils/removeQueryParams"

type InitializeAuth = {
  authUrl: string
  apiToken: string
  backofficeUrl: string
  loginUrl: string
  subdomain: string | undefined
  environment: string
  locale: string | undefined
  redirectStrategy?: RedirectStrategy
}

export const initializeAuth = (args: InitializeAuth): void => {
  const { remember_me, transfer_key, subdomain, picked_locale, impersonation } =
    getQueryParams()

  const subdomainFromUrl = subdomain ?? args.subdomain

  removeQueryParams([
    "access_token",
    "api_token",
    "company_name_key",
    "locale",
    "picked_locale",
    "remember_me",
    "subdomain",
    "transfer_key",
    "impersonation",
  ])

  setConfig({
    authUrl: args.authUrl,
    apiToken: args.apiToken,
    backofficeUrl: args.backofficeUrl,
    loginUrl: args.loginUrl,
    environment: args.environment,
    pickedLocale: picked_locale,
    subdomain: subdomainFromUrl,
    transferKey: transfer_key,
    rememberMe: remember_me === "true",
    impersonation: impersonation === "true",
    redirectStrategy: args.redirectStrategy ?? "origin",
    locale: args.locale,
  })
}
