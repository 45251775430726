import { v3 } from "backoffice-api"
import { useQueryAll } from "bonzai"
import { useEffect, useRef } from "react"
import { useTranslation } from "react-i18next"
import { custom } from "../../bonzai/bonzai"
import { getIsLeaderboardProduct } from "../../dataUtilities/productDataUtilities"
import { usePickText } from "../../i18n/usePickText"
import { useBannerStore } from "../../stores/useBannerStore"
import { triggerConfetti } from "../../tools/triggerConfetti"
import { CompletedProductBanner } from "./CompletedProductBanner"

export const useSyncBannerProductId = (productId: number | string) => {
  const setProductId = useBannerStore((store) => store.setProductId)

  useEffect(() => {
    setProductId(Number(productId))
  }, [productId, setProductId])
}

export const CompletedProductBannerLoader = () => {
  const { t } = useTranslation()
  const { productId, isVisible } = useBannerStore()
  const [product, status] = useData(productId)
  const pickText = usePickText()
  const isLeaderboardProduct =
    product && getIsLeaderboardProduct(product.product_type.identifier)

  useHideAfterTimeout()

  const getWatchValue = () => {
    if (product === undefined) return undefined
    if (isLeaderboardProduct) return status?.high_score
    return status?.certified
  }

  useWatchValue(productId, getWatchValue())

  if (product === undefined || status === undefined) return null

  const label = isLeaderboardProduct
    ? `${status.high_score} ${t("winningBox.PRODUCT_SIM_SCORE")}`
    : t("winningBox.PRODUCT_NONSIM_COMPLETED")

  return (
    <CompletedProductBanner
      isVisible={isVisible}
      title={pickText(product.title)}
      label={label}
      variant={isLeaderboardProduct ? "points" : "completed"}
    />
  )
}

const useData = (productId: number | undefined) => {
  return useQueryAll(() => [
    v3.getProduct.useQuery([productId!], {
      enabled: productId !== undefined,
      suspense: false,
      select: (res) => res.data,
    }),

    custom.getProductProgress.useQuery([productId!, { apiVersion: "v1" }], {
      refetchOnWindowFocus: "always",
      suspense: false,
      enabled: productId !== undefined,
    }),
  ])
}

const useWatchValue = (
  productId: number | undefined,
  value: number | boolean | undefined
) => {
  const { setIsVisible } = useBannerStore()
  const ref = useRef({ productId, value })

  useEffect(() => {
    const { productId: previousProductId, value: previousValue } = ref.current
    ref.current = { productId, value }

    if (value === undefined || previousValue === undefined) return

    const productHasChanged = productId !== previousProductId
    if (productHasChanged) return

    if (value > previousValue) {
      triggerConfetti()
      setIsVisible(true)
    }
  }, [productId, value, setIsVisible])
}

const useHideAfterTimeout = () => {
  const { isVisible, setIsVisible } = useBannerStore()

  useEffect(() => {
    if (!isVisible) return

    const timeout = setTimeout(() => {
      setIsVisible(false)
    }, 3000)

    return () => clearTimeout(timeout)
  }, [isVisible, setIsVisible])
}
