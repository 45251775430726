import { mock } from "mock"
import * as authApiProd from "./auth"

const notMockable = () => {
  throw new Error("Non mockable")
}

export const authApiMock = mock(authApiProd, (mocks) => ({
  getActiveSession: () => ({
    accessToken: "1234",
    companyNameKey: "test",
    logo: "",
    subdomain: "test",
    userId: 0,
    pickedLocale: "en-US",
    impersonation: false,
    environment: "test",
    rememberMe: false,
  }),

  getLinkWithActiveSession: () => "",
  getSessions: () => [mocks.getActiveSession()],
  getSubdomainFromPath: () => "",
  initializeAuth: (): undefined => undefined,
  logoutAndGetNext: () => mocks.getActiveSession(),
  accessTokenToTransferKey: () => "",
  updateConfig: () => {},

  redirectWithActiveSession: notMockable,
  loginNewSession: notMockable,
  logoutAndLogin: notMockable,
  logoutAndSLORedirect: notMockable,
  refreshLogin: notMockable,
}))
