import type { ReactNode } from "react"
import { sanitizeHtml } from "ui-tools"
import {
  FiestaImage,
  FiestaLink,
  type FiestaLinkProps,
} from "utility-components"
import s from "./NewsWidgetMobile.module.scss"

export const NewsWidgetMobile = ({ children }: { children: ReactNode }) => {
  return (
    <div className={s.newsWidget}>
      <div className={s.widget}>{children}</div>
    </div>
  )
}

const Item = ({
  link,
  title,
  description,
  image,
  arrow,
}: {
  link: FiestaLinkProps
  title: string
  description: string
  image: string
  arrow: string
}) => {
  return (
    <FiestaLink {...link} className={s.item}>
      <FiestaImage className={s.item__image} src={image} alt="" sizes="60px" />
      <div className={s.item__wrapper}>
        <div className={s.item__title}>{title}</div>
        <h3
          className={s.item__description}
          dangerouslySetInnerHTML={sanitizeHtml(description, "strict")}
        />
      </div>
      <div className={s.item__arrowContainer}>
        <FiestaImage
          className={s.item__arrow}
          src={arrow}
          alt=""
          sizes="20px"
        />
      </div>
    </FiestaLink>
  )
}

const Skeleton = () => {
  return (
    <div className={s.skeleton}>
      <div className={s.skeleton__image} />
      <div className={s.skeleton__wrapper}>
        <div className={s.skeleton__title} />
        <div className={s.skeleton__description} />
      </div>
      <div className={s.skeleton__arrow} />
    </div>
  )
}

type EmptyProps = {
  emptyText: string
}
const Empty = ({ emptyText }: EmptyProps) => (
  <div className={s.empty}>{emptyText}</div>
)

NewsWidgetMobile.Item = Item
NewsWidgetMobile.Skeleton = Skeleton
NewsWidgetMobile.Empty = Empty
