import helpAttensiIcon from "assets/icons/helpAttensiIcon.svg"
import languageIcon from "assets/icons/languageIcon.svg"
import legalIcon from "assets/icons/legalIcon.svg"
import adminLogo from "assets/logos/adminLogo.svg"
import { auth } from "auth"
import { v1, v3 } from "backoffice-api"
import { useQueryAll } from "bonzai"
import { useMenu } from "hooks"
import { Dropdown } from "materia"
import { useTranslation } from "react-i18next"
import { QueryBoundary } from "utility-components"
import { useSnapshot } from "valtio"
import { custom } from "../../bonzai/bonzai"
import { getHasAdminAccess } from "../../dataUtilities/getHasAdminAccess"
import { getLocaleDisplayName } from "../../dataUtilities/getLocaleDisplayName"
import { ADMIN_URL, APP_ENV } from "../../env"
import { changeLocale } from "../../i18n/changeLocale"
import { APP_VERSION, IS_APP } from "../../vuplex/constants"
import { sendVuplexMessage } from "../../vuplex/sendVuplexMessage"
import { vuplexStore } from "../../vuplex/vuplexStore"
import { CompanyPickerLoader } from "../CompanyPicker/CompanyPickerLoader"
import { HeaderMenu } from "./HeaderMenu"

export const HeaderMenuLoader = () => {
  const { isOpen, toggleMenu, containerRef } = useMenu()

  return (
    <HeaderMenu
      isOpen={isOpen}
      toggleIsOpen={toggleMenu}
      containerRef={containerRef}
    >
      <QueryBoundary>
        <Load toggleMenu={toggleMenu} />
      </QueryBoundary>
    </HeaderMenu>
  )
}

type LoadProps = {
  toggleMenu: () => void
}
const Load = ({ toggleMenu }: LoadProps) => {
  const { t, i18n } = useTranslation()
  const { pushNotificationsEnabled } = useSnapshot(vuplexStore)

  const [me, company, companyV3, companyV3Settings, flags] = useData()

  const privacyPolicyUrl = company.privacy_policies.find(
    (policy) => policy.locale === i18n.language
  )?.html_url

  const disableHelpCenter =
    companyV3Settings.selected_feature_flags.includes("disable_help_links")

  const enableSLORedirect = companyV3Settings.selected_feature_flags.includes(
    "enable_single_log_out"
  )

  const onLogout = () => {
    sendVuplexMessage({ type: "LOGOUT" })
    if (enableSLORedirect) auth.logoutAndSLORedirect()
    else auth.logoutAndLogin()
  }

  const togglePushNotifications = (pushNotificationsEnable: boolean) => {
    sendVuplexMessage({
      type: "REQUEST_PUSH_NOTIFICATIONS_ENABLED",
      payload: { pushNotificationsEnable },
    })
  }

  const appVersionText =
    APP_VERSION && `${t("settings.APP_VERSION")} ${APP_VERSION}`

  const onLanguageChange = (localeCode: string) => {
    changeLocale(localeCode)
    sendVuplexMessage({ type: "SET_LANGUAGE", payload: { localeCode } })
  }

  const showDeleteMe = companyV3.self_registration && APP_ENV === "production"

  return (
    <HeaderMenu.Content>
      <HeaderMenu.ContentHeader toggleIsOpen={toggleMenu} />
      {!IS_APP && (
        <HeaderMenu.CompanyItem
          companyName={company.name}
          icon={company.logo}
          menu={<CompanyPickerLoader />}
        />
      )}

      {!flags.disable_user_profile && (
        <HeaderMenu.BigItem
          title={t("settings.YOUR_PROFILE")}
          description={me.position ?? ""}
          icon={me.image}
          isBigIcon={true}
          onClick={toggleMenu}
          to="/me"
        />
      )}

      <Dropdown value={i18n.language} onChange={onLanguageChange}>
        <Dropdown.CustomButton>
          <HeaderMenu.BigItem
            title={t("settings.LANGUAGE")}
            icon={languageIcon}
            description={getLocaleDisplayName(i18n.language)}
            isInteractive={false}
          />
        </Dropdown.CustomButton>
        <Dropdown.Options>
          {company.portal_supported_locales.map((locale) => (
            <Dropdown.Option key={locale.code} value={locale.code}>
              {getLocaleDisplayName(locale.code)}
            </Dropdown.Option>
          ))}
        </Dropdown.Options>
      </Dropdown>

      {!disableHelpCenter && (
        <HeaderMenu.BigItem
          title={t("settings.HELP_ATTENSI")}
          icon={helpAttensiIcon}
          description={t("settings.HELP_ATTENSI_DESCRIPTION")}
          onClick={toggleMenu}
          href="https://help.attensi.com/hc/en-us"
        />
      )}

      <HeaderMenu.BigItem
        title={t("settings.LEGAL_REQUIREMENTS")}
        icon={legalIcon}
        menu={
          <HeaderMenu.LegalItems>
            <HeaderMenu.LegalItem
              title={t("settings.MINIMUM_TECHNICAL_SPECIFICATION")}
              href="https://legal.attensi.com/min-spec.html"
            />
            {privacyPolicyUrl && (
              <HeaderMenu.LegalItem
                title={t("settings.PRIVACY_POLICY")}
                href={privacyPolicyUrl}
              />
            )}
            <HeaderMenu.LegalItem
              title={t("settings.TERMS_OF_USE")}
              href="https://legal.attensi.com/eula.html"
            />
            <HeaderMenu.LegalItem
              title={t("settings.LICENSES")}
              href="https://legal.attensi.com/licenses.html"
            />
          </HeaderMenu.LegalItems>
        }
      />

      {pushNotificationsEnabled !== undefined && (
        <HeaderMenu.ToggleItem
          title={t("settings.PUSH_NOTIFICATIONS")}
          checked={pushNotificationsEnabled}
          onChange={togglePushNotifications}
        />
      )}

      {getHasAdminAccess(me) && (
        <HeaderMenu.SmallItem onClick={openAdmin}>
          <img src={adminLogo} alt="" />
          {t("settings.ADMIN")}
        </HeaderMenu.SmallItem>
      )}

      {showDeleteMe && (
        <HeaderMenu.SmallItem onClick={openDeleteMe}>
          {t("settings.DELETE_MY_DATA")}
        </HeaderMenu.SmallItem>
      )}

      <HeaderMenu.SmallItem title={t("settings.LOGOUT")} onClick={onLogout} />

      {appVersionText && <HeaderMenu.AppVersion version={appVersionText} />}
    </HeaderMenu.Content>
  )
}

const useData = () => {
  return useQueryAll(() => [
    v1.getMe.useQuery(),

    v1.getCurrentCompany.useQuery(),

    v3.getCurrentCompany.useQuery({
      select: (res) => res.data,
    }),

    v3.getCurrentCompanySettings.useQuery({
      select: (res) => res.data,
    }),

    custom.getPortalConfigurations.useQuery(),
  ])
}

const openAdmin = async () => {
  const link = await auth.getLinkWithActiveSession({ url: ADMIN_URL })

  if (IS_APP) {
    sendVuplexMessage({
      type: "OPEN_LINK",
      payload: { link, openExternalBrowser: true },
    })
  } else {
    window.location.href = link
  }
}

const openDeleteMe = async () => {
  const link = await auth.getLinkWithActiveSession({
    url: "https://delete-me.attensi.com",
  })

  window.location.href = link
}
