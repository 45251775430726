import { useNavigate } from "@tanstack/react-location"
import { v1, v3 } from "backoffice-api"
import { showErrorPopup } from "error-popup"
import { isFetchError } from "fetcher"
import { useScrollToTopOnMount } from "hooks"
import { t } from "i18next"
import { Input, Loader, Toggle } from "materia"
import {
  useForm,
  type UseFormRegister,
  type UseFormSetError,
} from "react-hook-form"
import { useTranslation } from "react-i18next"
import { QueryBoundary } from "utility-components"
import { useFormatUser } from "../../bonzai/useFormatUser"
import { sendVuplexMessage } from "../../vuplex/sendVuplexMessage"
import { UserSettingsView } from "./UserSettingsView"

type FormDataType = {
  userName: string | null
  avatarId: string | null
  showMeOnLeaderboards: boolean
}

export const UserSettingsViewLoader = () => {
  useScrollToTopOnMount()

  return (
    <UserSettingsView>
      <QueryBoundary fallback={<Loader />}>
        <Load />
      </QueryBoundary>
    </UserSettingsView>
  )
}

const Load = () => {
  const { t } = useTranslation()

  const me = v1.getMe.useQuery()
  const isAnonymousEnabled = useIsAnonymousEnabled()

  const defaultValues: FormDataType = {
    userName: me.username,
    showMeOnLeaderboards: !me.anonymous,
    avatarId: null,
  }

  const form = useForm({ defaultValues })
  const { register, formState, handleSubmit, setError } = form
  const { errors, isSubmitting, isDirty } = formState

  const onSubmit = useOnSubmit(setError)

  return (
    <UserSettingsView.Form onSubmit={handleSubmit(onSubmit)}>
      <TopLoader />
      {isAnonymousEnabled && (
        <Toggle
          label={t("userProfile.NOT_ANONYMOUS")}
          {...register("showMeOnLeaderboards")}
        />
      )}
      <Input
        label={t("userProfile.USERNAME")}
        errorMessage={errors.userName?.message}
        {...register("userName", { validate: validateUserName })}
      />
      <Input label="Mail" disabled value={me.email ?? ""} />
      <Input label="Phone" disabled value={me.phone_number} />
      <AvatarsLoader register={register} />
      <UserSettingsView.Buttons>
        <UserSettingsView.Button
          type="submit"
          disabled={isSubmitting || !isDirty}
        >
          {t("userProfile.SAVE")}
        </UserSettingsView.Button>
      </UserSettingsView.Buttons>
    </UserSettingsView.Form>
  )
}

const TopLoader = () => {
  const me = v1.getMe.useQuery()
  const formatUser = useFormatUser()
  return (
    <UserSettingsView.Top
      avatarUrl={me.image}
      userName={formatUser(me)}
      userPosition={me.position ?? ""}
    />
  )
}

type AvatarsLoaderProps = {
  register: UseFormRegister<FormDataType>
}
const AvatarsLoader = ({ register }: AvatarsLoaderProps) => {
  const { t } = useTranslation()

  const avatars = v1.getAvatars.useQuery({
    select: (res) => res.flatMap((group) => group.avatars),
  })

  const avatarElements = avatars.map((avatar) => (
    <UserSettingsView.Avatar
      key={avatar.id}
      src={avatar.url}
      value={avatar.id}
      alt={avatar.title}
      {...register("avatarId")}
    />
  ))

  return (
    <UserSettingsView.Avatars label={t("userProfile.CHOOSE_AVATAR")}>
      {avatarElements}
    </UserSettingsView.Avatars>
  )
}

const useIsAnonymousEnabled = () => {
  return v3.getCurrentCompanySettings.useQuery({
    select: (res) => {
      const featureFlags = res.data.selected_feature_flags
      return featureFlags.includes("anonymous")
    },
  })
}

const useOnSubmit = (setError: UseFormSetError<FormDataType>) => {
  const navigate = useNavigate()

  const updateUser = async (data: FormDataType) => {
    const res = await v1.updateUser({
      anonymous: !data.showMeOnLeaderboards,
      username: data.userName,
      avatar_id: data.avatarId !== null ? data.avatarId : undefined,
    })

    sendVuplexMessage({ type: "USER_SETTINGS_UPDATE" })

    v1.getMe.setQueryData(res)
    navigate({ to: "/for-you" })
  }

  return async (data: FormDataType) => {
    try {
      await updateUser(data)
    } catch (error) {
      if (isFetchError(error) && (error.json as any).username) {
        setError("userName", { message: (error.json as any).username[0] })
      } else {
        showErrorPopup({ error })
      }
    }
  }
}

const validateUserName = async (userName: string | null) => {
  if (userName === null) return

  const me = await v1.getMe.fetchQueryOnce()

  if (me.username === userName) return

  if (userName === "") return t("onboarding.USERNAME_EXISTS")

  const { available } = await v1.getIsUsernameAvailable(userName)
  if (!available) return t("onboarding.USERNAME_EXISTS")
}
