import Plausible from "plausible-tracker"
import { APP_ENV } from "../env"

export const initializePlausible = () => {
  if (APP_ENV !== "production") return

  const plausible = Plausible({
    trackLocalhost: false,
    apiHost: "https://plausible.attensi.com",
    domain: "portal.attensi.com",
  })

  plausible.enableAutoPageviews()
}
