import {
  SOURCE_CONFIG,
  type OptimizerApp,
  type OptimizerParams,
} from "optimizer-core"
import { combinePaths, withQueryParams } from "utils"

type Params = {
  optimizeUrl: string
  app: OptimizerApp
  src: string | null | undefined
  width?: number
  height?: number
}

export const optimizeImage = (params: Params) => {
  const { src, height, width } = params
  if (!src) return ""

  const optimizedUrl = replaceSourceOrigin(src, params)
  const queryParams: OptimizerParams = {
    h: height,
    w: width,
  }
  return withQueryParams(optimizedUrl, queryParams)
}

const replaceSourceOrigin = (src: string, { optimizeUrl, app }: Params) => {
  for (const source of SOURCE_CONFIG) {
    if (src.startsWith(source.baseUrl)) {
      const withoutBase = src.replace(source.baseUrl, "")
      return combinePaths(optimizeUrl, source.name, app.name, withoutBase)
    }
  }
  return src
}
