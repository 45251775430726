import type { v3 } from "backoffice-api"
import DOMPurify from "dompurify"
import { t } from "i18next"
import icalGenerator from "ical-generator"
import { downloadBlob } from "utils"
import { pickTextAsync } from "../i18n/usePickText"
import { IS_APP } from "../vuplex/constants"
import { sendVuplexMessage } from "../vuplex/sendVuplexMessage"

type Product = v3["getProduct"]["data"]
type Course = v3["getProductCourse"]["data"]

export const addToCalendar = async (product: Product, course: Course) => {
  if (IS_APP) {
    addToCalendarVuplex(product, course)
  } else {
    await addToCalendarIcal(product, course)
  }
}

const addToCalendarVuplex = async (product: Product, course: Course) => {
  sendVuplexMessage({
    type: "ADD_TO_CALENDAR",
    payload: {
      title: await pickTextAsync(product.title),
      startDatetime: course.start_datetime,
      endDatetime: course.end_datetime,
      location: course.location,
      description: await addLinksToDescription(product, course, false),
    },
  })
}

const addToCalendarIcal = async (product: Product, course: Course) => {
  const cal = icalGenerator()
  const interpolatedHTMLDescription = await addLinksToDescription(
    product,
    course,
    false
  )
  const interpolatedPlainDescription = await addLinksToDescription(
    product,
    course,
    true
  )

  cal.createEvent({
    summary: await pickTextAsync(product.title),
    start: course.start_datetime,
    end: course.end_datetime,
    location: course.location || course.meeting_link,
    description: {
      plain: interpolatedPlainDescription,
      html: interpolatedHTMLDescription,
    },
    url: course.meeting_link,
  })

  const blob = cal.toBlob()
  const fileName = `calendar-invite-${new Date().toISOString()}.ics`
  downloadBlob(blob, fileName)
}

const addLinksToDescription = async (
  product: Product,
  course: Course,
  stripHTML: boolean
) => {
  const mainDescription = await formattedMainDescription(product, stripHTML)

  const link = stripHTML ? plainLink : htmlLink

  const meetingLinkText = t("event.JOIN_VIDEO_MEETING")
  const courseMaterialText = t("event.COURSE_MATERIAL_LINK")

  const meetingLink = link(course.meeting_link, meetingLinkText)
  const courseMaterialLink = link(course.course_material, courseMaterialText)

  return `${mainDescription} ${meetingLink} ${courseMaterialLink}`
}

const formattedMainDescription = async (
  product: Product,
  stripHTML: boolean
) => {
  if (stripHTML) {
    return DOMPurify.sanitize(
      await pickTextAsync(product.description_long, ""),
      { ALLOWED_TAGS: ["#text"] }
    )
  }

  return await pickTextAsync(product.description_long, "")
}

const htmlLink = (href: string | null, linkText: string) => {
  if (!href) return ""

  return `<p> <a href="${href}"> ${linkText} </a>  </p>`
}

const plainLink = (href: string | null, linkText: string) => {
  if (!href) return ""

  return `\n\n${linkText}: ${href}`
}
