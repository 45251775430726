import { withQueryParams } from "utils"
import { accessTokenToTransferKey } from "./api/transferKey"
import { getActiveSession } from "./getActiveSession"

type AccessType = "accessToken" | "transferKey"

type Options = {
  url: string
  accessType?: AccessType
}

export const getLinkWithActiveSession = async ({
  url,
  accessType = "transferKey",
}: Options) => {
  const { subdomain, accessToken, companyNameKey } = await getActiveSession()

  if (accessType === "accessToken") {
    return withQueryParams(url, {
      subdomain,
      access_token: accessToken,
      company_name_key: companyNameKey,
    })
  }

  return withQueryParams(url, {
    subdomain,
    transfer_key: await accessTokenToTransferKey(accessToken),
    company_name_key: companyNameKey,
  })
}
