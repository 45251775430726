import closeIcon from "assets/icons/closeIcon.svg"
import { ReactComponent as LikeIcon } from "assets/icons/likeIcon.svg"
import { Dialog } from "materia"
import type { PropsWithChildren } from "react"
import { sanitizeHtml } from "ui-tools"
import { FiestaImage, FiestaLink } from "utility-components"
import { NewsCardLikeButton } from "../NewsCard/NewsCard"
import s from "./NewsItemView.module.scss"

export const NewsItemView = ({ children }: PropsWithChildren) => (
  <Dialog className={s.view} aria-label="News post">
    {children}
  </Dialog>
)

type ContentProps = {
  image: string
  title: string
  description: string
}
const Content = ({ image, title, description }: ContentProps) => (
  <div className={s.content}>
    <FiestaImage
      className={s.content__image}
      src={image}
      alt="News post"
      sizes="(width <= small) 100vw, 50vw"
    />
    <h1 className={s.content__title}>{title}</h1>
    <div
      className={s.content__description}
      dangerouslySetInnerHTML={sanitizeHtml(description, "allow-links")}
    />
  </div>
)

type BottomProps = {
  numberOfLikes: number
  displayDate: string
  onLike: () => void
  likeText: string
  isLiked: boolean
}
const Bottom = ({
  numberOfLikes,
  displayDate,
  onLike,
  likeText,
  isLiked,
}: BottomProps) => (
  <div className={s.bottom}>
    <NewsCardLikeButton isLiked={isLiked} onClick={onLike} text={likeText} />
    <div className={s.bottom__likes}>
      <LikeIcon />
      {numberOfLikes}
    </div>
    <div className={s.bottom__date}>{displayDate}</div>
  </div>
)

type CloseButtonProps = {
  closeRoute: string
}
const CloseButton = ({ closeRoute }: CloseButtonProps) => (
  <FiestaLink className={s.closeButton} to={closeRoute}>
    <img className={s.closeButton__icon} src={closeIcon} alt="Close" />
  </FiestaLink>
)

const Skeleton = () => (
  <div className={s.skeleton}>
    <div className={s.skeleton__content}>
      <div className={s.skeleton__image} />
      <div className={s.skeleton__title} />
      <ul className={s.skeleton__description}>
        <li className={s.skeleton__descriptionItem} />
        <li className={s.skeleton__descriptionItem} />
        <li className={s.skeleton__descriptionItem} />
        <li className={s.skeleton__descriptionItem} />
        <li className={s.skeleton__descriptionItem} />
        <li className={s.skeleton__descriptionItem} />
        <li className={s.skeleton__descriptionItem} />
      </ul>
    </div>
    <div className={s.skeleton__bottom} />
  </div>
)

NewsItemView.Content = Content
NewsItemView.Bottom = Bottom
NewsItemView.CloseButton = CloseButton
NewsItemView.Skeleton = Skeleton
