import { runFetch } from "fetcher"
import {
  Struct,
  assert,
  boolean,
  nullable,
  optional,
  string,
  type,
} from "superstruct"
import { getConfig } from "../config"

const RevokeAccessTokenResponse = type({
  success: boolean(),
  single_log_out_redirect_url: optional(nullable(string())),
})

export const revokeAccessToken = async (session: { accessToken: string }) => {
  const { apiToken, backofficeUrl } = await getConfig()

  const data = await runFetch({
    baseUrl: backofficeUrl,
    path: `/api/v1/oauth/revoke`,
    method: "POST",
    headers: {
      "API-Token": apiToken,
      Authorization: `Bearer ${session.accessToken}`,
    },
    data: {
      token: session.accessToken,
      token_type_hint: "access_token",
    },
    transform: (data) => validate(RevokeAccessTokenResponse, data),
  })

  return data
}

const validate = async <T>(schema: Struct<T>, data: unknown) => {
  assert(data, schema)
  return data
}
