import { createResolvable } from "utils"
import type { RedirectStrategy } from "./types/RedirectStrategy"

export type AuthConfig = {
  authUrl: string
  apiToken: string
  backofficeUrl: string
  loginUrl: string
  pickedLocale: string | undefined
  subdomain: string | undefined
  transferKey: string | undefined
  rememberMe: boolean
  impersonation: boolean
  environment: string
  redirectStrategy: RedirectStrategy
  locale: string | undefined
}

let { promise, resolve } = createResolvable<AuthConfig>()

export const getConfig = () => promise
export const setConfig = resolve

export const updateConfig = async (configChanges: Partial<AuthConfig>) => {
  const config = await promise
  const newConfig = { ...config, ...configChanges }
  promise = Promise.resolve(newConfig)
}
