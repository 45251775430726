import { v1, v3 } from "backoffice-api"
import { useQueryAll } from "bonzai"
import type { PropsWithChildren } from "react"
import { useTranslation } from "react-i18next"
import { QueryBoundary } from "utility-components"
import { custom } from "../../bonzai/bonzai"
import { useFormatUser } from "../../bonzai/useFormatUser"
import { HeaderMenuLoader } from "../HeaderMenu/HeaderMenuLoader"
import { NavigationLoader } from "../Navigation/NavigationLoader"
import { NoPortalAccess } from "../NoPortalAccess/NoPortalAccess"
import { ProductPlayingLoader } from "../ProductPlaying/ProductPlayingLoader"
import { AppShell } from "./AppShell"

export const AppShellLoader = ({ children }: PropsWithChildren) => {
  console.log("Running old version")

  const [flags, companySettings] = useData()

  const backgroundImage = flags?.enable_portal_background
    ? companySettings?.data.portal_background_url
    : undefined

  return (
    <AppShell backgroundImage={backgroundImage}>
      <QueryBoundary fallback={<AppShell.HeaderSkeleton />}>
        <Header />
      </QueryBoundary>
      <AppShell.Main>
        <QueryBoundary>
          <Main>{children}</Main>
        </QueryBoundary>
      </AppShell.Main>
      <AppShell.Footer />
      <QueryBoundary>
        <AppShell.BottomBar>
          <ProductPlayingLoader />
          <AppShell.BottomNavigation>
            <NavigationLoader />
          </AppShell.BottomNavigation>
        </AppShell.BottomBar>
      </QueryBoundary>
    </AppShell>
  )
}

const Header = () => {
  const [currentCompany, me] = useHeaderData()
  const formatUser = useFormatUser()

  return (
    <AppShell.Header
      menu={<HeaderMenuLoader />}
      navigation={<NavigationLoader />}
      companyLogo={currentCompany.logo}
      avatarLink={{ to: "/me" }}
      userDisplayName={formatUser(me)}
      avatar={me.image}
    />
  )
}

const Main = ({ children }: PropsWithChildren) => {
  const hasAccess = useHasAccess()
  const { t } = useTranslation()

  if (hasAccess) return <>{children}</>

  return <NoPortalAccess noAccessText={t("notFound.NOT_ENABLED")} />
}

const useData = () => {
  return useQueryAll(() => [
    custom.getPortalConfigurations.useQuery({
      suspense: false, // Don't block rendering
    }),

    v3.getCurrentCompanySettings.useQuery({
      suspense: false, // Don't block rendering
    }),
  ])
}

const useHeaderData = () => {
  return useQueryAll(() => [
    v1.getCurrentCompany.useQuery(),
    v1.getMe.useQuery(),
  ])
}

const useHasAccess = () => {
  const supported_configurations = v3.getCurrentCompanySettings.useQuery({
    suspense: false,
    select: (res) => res.data.supported_configurations,
  })

  if (!supported_configurations) return true

  return supported_configurations.includes("portal")
}
