import { revokeAccessToken } from "./api/revokeAccessToken"
import { removeSessionFromStorage } from "./storage/sessionStorage"
import type { Session } from "./types/Session"

export const deleteAndRevokeSession = async (session: Session) => {
  const removedSessions = await removeSessionFromStorage(session)
  const removedSessionsStatus = await Promise.all(
    removedSessions.map(revokeAccessToken)
  )
  return removedSessionsStatus
}
