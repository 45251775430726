import { useMatch } from "@tanstack/react-location"
import { v3 } from "backoffice-api"
import { useScrollToTopOnMount } from "hooks"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { QueryBoundary } from "utility-components"
import { toggleLike } from "../../bonzai/toggleLike"
import { getElapsedTime } from "../../dataUtilities/getElapsedTime"
import { usePickText } from "../../i18n/usePickText"
import { NewsItemView } from "./NewsItemView"

type NewsPost = v3["getNewsPost"]["data"]

export const NewsItemViewLoader = () => {
  useScrollToTopOnMount()

  return (
    <NewsItemView>
      <NewsItemView.CloseButton closeRoute="/news" />
      <QueryBoundary fallback={<NewsItemView.Skeleton />}>
        <Load />
      </QueryBoundary>
    </NewsItemView>
  )
}

const Load = () => {
  const { i18n, t } = useTranslation()

  const newsPostId = useMatch().params.newsPostId!
  const { data: post } = v3.getNewsPost.useQuery([newsPostId])
  const pickText = usePickText()

  useReportNewsView(post)

  return (
    <>
      <NewsItemView.Content
        image={post.image?.file_url ?? ""}
        title={pickText(post.headline)}
        description={pickText(post.body)}
      />
      <NewsItemView.Bottom
        numberOfLikes={post.likes_count}
        displayDate={getElapsedTime({
          date: post.updated_at,
          locale: i18n.language,
        })}
        likeText={post.is_liked ? t("news.LIKED") : t("news.LIKE")}
        isLiked={post.is_liked}
        onLike={() => toggleLike(post)}
      />
    </>
  )
}

const useReportNewsView = (post: NewsPost) => {
  useEffect(() => {
    v3.createNewsPostReaction({
      news_post_id: post.id,
      reaction_type: "view",
    }).catch(console.warn)
  }, [post])
}
