import { auth } from "auth"
import { useTranslation } from "react-i18next"
import { CompanyPicker } from "./CompanyPicker"

export const CompanyPickerLoader = () => {
  const { t } = useTranslation()

  const sessions = auth.getSessions.useQuery()
  const activeSession = auth.getActiveSession.useQuery()

  const companies = sessions
    .filter((session) => activeSession.accessToken !== session.accessToken)
    .map((session, index) => (
      <CompanyPicker.Company
        key={index}
        companyName={session.companyNameKey}
        companyLogo={session.logo}
        href={`/${session.subdomain}`}
      />
    ))

  return (
    <CompanyPicker>
      <CompanyPicker.Companies>{companies}</CompanyPicker.Companies>
      <CompanyPicker.Bottom
        addButtonText={t("sessions.ADD_SESSION")}
        onAddCompany={() => auth.loginNewSession()}
      />
    </CompanyPicker>
  )
}
