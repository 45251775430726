import { BREAKPOINTS } from "materia"
import type { ImgHTMLAttributes } from "react"
import {
  optimizeImage,
  useOptimizeContext,
  type OptimizeContextType,
} from "ui-tools"
import { exists } from "utils"

type Breakpoint = `${"small" | "medium" | "big"}`
type Size = `${number}${"vw" | "px"}`
type EqualityOperator = "<" | "<=" | ">" | ">=" | "="
type ResponsiveSize = `(width ${EqualityOperator} ${Breakpoint}) ${Size}`
type Sizes =
  | Size
  | `${ResponsiveSize}, ${Size}`
  | `${ResponsiveSize}, ${ResponsiveSize}, ${Size}`

type ImageProps = Omit<
  ImgHTMLAttributes<HTMLImageElement>,
  "alt" | "srcSet" | "sizes"
> & {
  alt: string

  /**
   * Define element size for different breakpoints.
   *
   * @example `500px`
   * @example `50vw`
   * @example `(width <= medium) 100vw, 50w`
   * @example `(width = medium) 50px, (width = big) 100px, 100vw`
   */
  sizes: Sizes

  height?: number
}

export const FiestaImage = ({
  src,
  alt,
  sizes,
  height,
  ...props
}: ImageProps) => {
  const context = useOptimizeContext()

  return (
    <img
      {...props}
      alt={alt}
      srcSet={getSrcSet(src, context, height)}
      sizes={getSizes(sizes)}
      src={src}
    />
  )
}

const getSrcSet = (
  src: string | undefined,
  context: OptimizeContextType | null,
  targetHeight: number | undefined
) => {
  if (
    !src ||
    !context ||
    context.app === undefined ||
    context.optimizeUrl === undefined
  ) {
    return undefined
  }

  const { app, optimizeUrl } = context

  const height = targetHeight
    ? app.heights.find((height) => height >= targetHeight)
    : undefined

  return app.widths
    .map((width) => {
      const url = optimizeImage({ src, width, height, app, optimizeUrl })
      return `${url} ${width}w`
    })
    .join(", ")
}

const getSizes = (sizes: string | undefined) => {
  if (!sizes) return undefined

  const { MEDIUM, SMALL } = BREAKPOINTS

  return sizes
    .replace("(width = small)", mediaQuery({ max: SMALL }))
    .replace("(width >= small)", mediaQuery({ min: 0 }))
    .replace("(width > small)", mediaQuery({ min: SMALL }))
    .replace("(width <= small)", mediaQuery({ max: SMALL }))
    .replace("(width < small)", mediaQuery({ max: 0 }))

    .replace("(width = medium)", mediaQuery({ min: SMALL, max: MEDIUM }))
    .replace("(width >= medium)", mediaQuery({ min: SMALL }))
    .replace("(width > medium)", mediaQuery({ min: MEDIUM }))
    .replace("(width <= medium)", mediaQuery({ max: MEDIUM }))
    .replace("(width < medium)", mediaQuery({ max: SMALL }))

    .replace("(width = big)", mediaQuery({ min: MEDIUM }))
    .replace("(width >= big)", mediaQuery({ min: MEDIUM }))
    .replace("(width > big)", mediaQuery({ max: 0 }))
    .replace("(width <= big)", mediaQuery({ min: 0 }))
    .replace("(width < big)", mediaQuery({ max: MEDIUM }))
}

const mediaQuery = ({ min, max }: { min?: number; max?: number } = {}) => {
  const minQuery = min ? `(min-width: ${min}px)` : undefined
  const maxQuery = max ? `(max-width: ${max - 1}px)` : undefined

  return [minQuery, maxQuery].filter(exists).join(" and ")
}
