import { v3 } from "backoffice-api"
import {
  invalidateAllPlaythroughs,
  invalidatePlaythroughDependencies,
} from "../bonzai/createPlaythrough"
import { changeLocale } from "../i18n/changeLocale"
import { sendVuplexMessage } from "../vuplex/sendVuplexMessage"
import { subscribeToVuplex } from "../vuplex/subscribeToVuplex"
import { vuplexStore } from "../vuplex/vuplexStore"

export const initializeVuplex = () => {
  subscribeToVuplex("SET_LANGUAGE", (event) => {
    onLanguageUpdate(event.payload.localeCode)
  })

  subscribeToVuplex("SET_MINI_MODAL_PRODUCT_ID", (message) => {
    vuplexStore.productId = message.payload
  })

  subscribeToVuplex("CLOSE_PRODUCT_MODALS", () => {
    vuplexStore.productId = undefined
  })

  subscribeToVuplex("REFRESH_PRODUCT_SCORE", () => {
    invalidateAllPlaythroughs()
    invalidatePlaythroughDependencies()
  })

  subscribeToVuplex("SET_PUSH_NOTIFICATIONS_ENABLED", (message) => {
    vuplexStore.pushNotificationsEnabled = message.payload
  })

  sendVuplexMessage({ type: "READY" })
}

const onLanguageUpdate = async (locale: string) => {
  const isValid = await isLocaleValid(locale)
  if (!isValid) return

  changeLocale(locale)
}

const isLocaleValid = async (locale: string) => {
  const res = await v3.getCurrentCompanySettings.fetchQueryOnce()

  const supportedLocales = res.data.supported_locales
  const localeCodes = supportedLocales.map((locale) => locale.code)
  return localeCodes.includes(locale)
}
