import { auth } from "auth"
import { v3 } from "backoffice-api"
import { useModal, useRouteParamNumber } from "hooks"
import { Loader } from "materia"
import { useEffect } from "react"
import { QueryBoundary } from "utility-components"
import { createPlaythrough } from "../../bonzai/createPlaythrough"
import { usePickText } from "../../i18n/usePickText"
import { sendVuplexMessage } from "../../vuplex/sendVuplexMessage"
import { ViewerModal } from "./ViewerModal"

type XapiModalLoaderProps = {
  closeRoute: string
}
export const XapiModalLoader = ({ closeRoute }: XapiModalLoaderProps) => {
  const productId = useRouteParamNumber("productId")

  useAutoRotation()

  const { onClose } = useModal(closeRoute, () => {
    createPlaythrough(productId, {
      mutate: false,
      invalidate: true,
      invalidateDependencies: true,
    })
  })

  return (
    <ViewerModal>
      <QueryBoundary fallback={<Loader />}>
        <Load productId={productId} onClose={onClose} />
      </QueryBoundary>
    </ViewerModal>
  )
}

type LoadProps = {
  productId: number
  onClose: () => void
}
const Load = ({ productId, onClose }: LoadProps) => {
  const pickText = usePickText()

  const product = v3.getProduct.useQuery([productId], {
    select: (res) => res.data,
  })

  const link = auth.getLinkWithActiveSession.useQuery([
    {
      url: product.content_link,
      accessType: "accessToken",
    },
  ])

  return (
    <ViewerModal.FullScreen
      headerText={pickText(product.title)}
      src={link}
      onClose={onClose}
    />
  )
}

const useAutoRotation = () => {
  useEffect(() => {
    sendVuplexMessage({
      type: "SET_SCREEN_ORIENTATION",
      payload: { screenOrientation: "AUTO" },
    })

    return () => {
      sendVuplexMessage({
        type: "SET_SCREEN_ORIENTATION",
        payload: { screenOrientation: "PORTRAIT" },
      })
    }
  }, [])
}
